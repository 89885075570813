import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Container from "@mui/material/Container";
import { useAuth } from "../../context/auth";
import { coinsAbv } from "../../utils/coins";
import { CircularProgress } from "@mui/material";
import CurrencySelect from "../CurrencySelect";
import LoadingScreen from "../LoadingScreen";
import { validateBalance } from "../../utils/FormValidations";
import Cookies from "js-cookie";
import { swapCoins } from "../../api/balance";
import { useNotification } from "../../context/notif";
import Balances from "../Balances";
import { NavLink } from "react-router-dom";

export default function Swap() {
  const { addNotification } = useNotification();
  const [buttonState, setButtonState] = useState(false);
  const [authError, setAuthError] = useState("");
  const [fromCurrency, setFromCurrency] = useState("bitcoin");
  const [toCurrency, setToCurrency] = useState("bitcoin");
  const { user, loading } = useAuth();

  const swapTransaction = async (values) => {
    let amount = values.amount;
    if (coinsAbv[fromCurrency] === coinsAbv[toCurrency]) {
      addNotification({
        title: "Error",
        message: "From and To cannot be the same currency",
        level: "error",
      });
      setButtonState(false);
      return;
    }
    try {
      const token = Cookies.get("token");
      const response = await swapCoins(
        token,
        JSON.stringify({
          convert_from: coinsAbv[fromCurrency],
          amount,
          convert_to: coinsAbv[toCurrency],
        })
      );
      if (response.status === 200) {
        addNotification({
          title: "Success",
          message: "Swap successful",
          level: "success",
        });
      }
      setButtonState(false);
    } catch (err) {
      setButtonState(false);
      addNotification({
        title: "Error",
        message: "An Unexpected Error has occured",
        level: "error",
      });
      if (err?.response) {
        setAuthError(err.response);
      } else {
        setAuthError("An Unexpected Error has occured");
      }
    }
  };
  if (loading) return <LoadingScreen loading={loading} />;
  return (
    <>
      <div className="flex flex-col max-w-sm  mb-8 border bg-black border-gray-500 rounded text-white">
        <h3 className="p-4 border-b border-gray-500 text-lg font-semibold">
          My Stakings
        </h3>
        <NavLink to="/staking" className="p-4 flex gap-3 hover:bg-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            height="48"
            width="48"
            data-element="Learn"
          >
            <path
              fill="#BFE9FF"
              d="M30 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
            ></path>
            <path
              fill="#56B4FC"
              d="M3 24c0-8.475 7.05-15.3 15.525-15C26.7 9.3 33 16.2 33 24.375V48H15v-9h-4.5c-1.65 0-3-1.35-3-3v-6H3v-6z"
            ></path>
            <path
              fill="#1652F0"
              d="M18.525 9c-.825 0-1.575 0-2.325.15-.75 1.8-1.2 3.825-1.2 5.85 0 8.25 6.75 15 15 15 1.05 0 2.025-.075 3-.3v-5.325C33 16.2 26.7 9.3 18.525 9z"
            ></path>
            <path
              fill="#fff"
              d="m24 13.5 1.05 3.45L28.5 18l-3.45 1.05L24 22.5l-1.05-3.45L19.5 18l3.45-1.05L24 13.5z"
            ></path>
          </svg>
          <div>
            <h4>Learn about Staking</h4>
            <p className="text-gray-500">See how we calculate rewards</p>
          </div>
        </NavLink>
      </div>
      <Balances
        totalBalance={user.balances.total.toFixed(2)}
        stakingBalance={user.balances.total_stakes.toFixed(2)}
      />

      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
        className="flex justify-center items-center px-0 mb-36"
      >
        <Formik
          initialValues={{
            amount: 0,
          }}
          onSubmit={(values) => {
            setButtonState(true);
            swapTransaction(values);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            validateOnChange,
            /* and other goodies */
          }) => (
            <Form className="form flex flex-col p-8 bg-black border border-gray-500 text-white rounded-sm w-full">
              <div className="w-full">
                <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                  From
                </p>
                <CurrencySelect
                  seletedCurrency={fromCurrency}
                  setseletedCurrency={setFromCurrency}
                />
              </div>
              <div className="w-full">
                <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                  Amount ($)
                </p>
                <Field
                  type="number"
                  name="amount"
                  required
                  min={1}
                  max={user.balances[
                    `${coinsAbv[fromCurrency]}_dollar`
                  ].toFixed(2)}
                  className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                  placeholder="$100"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  validate={(value) =>
                    validateBalance(
                      value,
                      user.balances[`${coinsAbv[fromCurrency]}_dollar`]
                    )
                  }
                />
                <p className="text-gray-200 font-medium text-xs mt-1 mr-0 mb-1 ml-0 block">
                  Balance: $
                  {user.balances[`${coinsAbv[fromCurrency]}_dollar`].toFixed(2)}
                </p>
                <div className="text-red-400">
                  {errors.amount && touched.username && errors.amount}
                </div>
              </div>
              {/* <div >

              </div> */}
              <SwapVertIcon className="self-center mt-4" fontSize="large" />
              <div className="w-full">
                <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                  To
                </p>
                <CurrencySelect
                  seletedCurrency={toCurrency}
                  setseletedCurrency={setToCurrency}
                />
              </div>

              <div className="form-submit">
                {buttonState && (
                  <div
                    className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                text-white bg-blue-700 hover:bg-blue-900 flex justify-center items-center"
                  >
                    <CircularProgress size={25} />
                  </div>
                )}
                {!buttonState && (
                  <input
                    className=" rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5 bg-green-500 text-white hover:bg-green-700"
                    type="submit"
                    disabled={buttonState}
                    value="Swap"
                  />
                )}
              </div>
              <div className="text-red-400 mt-4">
                {/* {authError ? <p>{authError} </p> : null} */}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
