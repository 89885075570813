import React, { useState, useRef } from "react";
import Container from "@mui/material/Container";
import Cookies from "js-cookie";
import { useAuth } from "../../context/auth";
import { CircularProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import CurrencySelect from "../CurrencySelect";
import { coinsAbv } from "../../utils/coins";
import { withdraw } from "../../api/balance";
import NotificationSystem from "react-notification-system";
import { validateBalance } from "../../utils/FormValidations";
import LoadingScreen from "../LoadingScreen";
import Balances from "../Balances";
import { NavLink } from "react-router-dom";

export default function Send() {
  const notificationSystem = useRef();

  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };
  const [seletedCurrency, setseletedCurrency] = useState("bitcoin");
  const [buttonState, setButtonState] = useState(false);
  const [authError, setAuthError] = useState("false");
  const { isAuthenticated, isLoading, user, loading } = useAuth();
  const sendTransaction = async (values) => {
    let amount = values.amount;
    let address = values.address;
    if (!user.kyc) {
      addNotification({
        title: "Error",
        message: "Complete your KYC to enable withdrawal",
        level: "error",
      });
      setButtonState(false);
      return;
    }
    try {
      const token = Cookies.get("token");
      const response = await withdraw(
        token,
        JSON.stringify({
          withdraw_from: coinsAbv[seletedCurrency],
          amount,
          wallet_address: address,
        })
      );
      if (response.status === 200) {
        addNotification({
          title: "Success",
          message: "Withdrawal Request successful",
          level: "success",
        });
      }
      setButtonState(false);
    } catch (err) {
      setButtonState(false);
      addNotification({
        title: "Error",
        message: "An Unexpected Error has occured",
        level: "error",
      });
      if (!err?.response) {
        setAuthError("No Server Response");
      } else {
        setAuthError("An Unexpected Error has occured");
      }
    }
  };
  if (loading) return <LoadingScreen loading={loading} />;
  return (
    <>
      <NotificationSystem ref={notificationSystem} />
      <div className="flex flex-col max-w-sm  mb-8 border bg-black border-gray-500 rounded text-white">
        <h3 className="p-4 border-b border-gray-500 text-lg font-semibold">
          My Stakings
        </h3>
        <NavLink to="/staking" className="p-4 flex gap-3 hover:bg-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            height="48"
            width="48"
            data-element="Learn"
          >
            <path
              fill="#BFE9FF"
              d="M30 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
            ></path>
            <path
              fill="#56B4FC"
              d="M3 24c0-8.475 7.05-15.3 15.525-15C26.7 9.3 33 16.2 33 24.375V48H15v-9h-4.5c-1.65 0-3-1.35-3-3v-6H3v-6z"
            ></path>
            <path
              fill="#1652F0"
              d="M18.525 9c-.825 0-1.575 0-2.325.15-.75 1.8-1.2 3.825-1.2 5.85 0 8.25 6.75 15 15 15 1.05 0 2.025-.075 3-.3v-5.325C33 16.2 26.7 9.3 18.525 9z"
            ></path>
            <path
              fill="#fff"
              d="m24 13.5 1.05 3.45L28.5 18l-3.45 1.05L24 22.5l-1.05-3.45L19.5 18l3.45-1.05L24 13.5z"
            ></path>
          </svg>
          <div>
            <h4>Learn about Staking</h4>
            <p className="text-gray-500">See how we calculate rewards</p>
          </div>
        </NavLink>
      </div>
      <Balances
        totalBalance={user.balances.total.toFixed(2)}
        stakingBalance={user.balances.total_stakes.toFixed(2)}
      />
      {/* <Grid container spacing={3} className="my-8">
        {coins.map((coin) => {
          return (
            <Grid item xs={6} md={3} lg={2} key={coin._name}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 100,
                }}
                className="bg-blue-700 text-white flex flex-col justify-between"
              >
                <h2>{coin._name}</h2>
                <Typography
                  component="p"
                  variant="h5"
                  className="font-semibold"
                >
                  ${user.balances[`${coin.id}_dollar`].toFixed(2)}
                </Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid> */}
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
        className="flex justify-center items-center px-0 mb-36"
      >
        <Formik
          initialValues={{
            amount: 0,
            address: "",
          }}
          onSubmit={(values) => {
            setButtonState(true);
            sendTransaction(values);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            validateOnChange,
            /* and other goodies */
          }) => (
            <Form className="form flex flex-col p-8 bg-black border border-gray-500 text-white rounded-sm w-full">
              <CurrencySelect
                seletedCurrency={seletedCurrency}
                setseletedCurrency={setseletedCurrency}
              />
              <p className="text-gray-200 font-medium text-xs mt-0 mr-0 mb-0 ml-0 block">
                Balance: $
                {user.balances[`${coinsAbv[seletedCurrency]}_dollar`].toFixed(
                  2
                )}
              </p>
              <div className="w-full">
                <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                  Amount
                </p>
                <Field
                  type="number"
                  required
                  max={user.balances[
                    `${coinsAbv[seletedCurrency]}_dollar`
                  ].toFixed(2)}
                  name="amount"
                  className="rounded p-4 w-full bg-black border border-white text-white"
                  placeholder="$100"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  validate={(value) =>
                    validateBalance(
                      value,
                      user.balances[`${coinsAbv[seletedCurrency]}_dollar`]
                    )
                  }
                />

                <div className="text-red-400 text-xs">
                  {errors.amount && touched.amount && errors.amount}
                </div>
              </div>
              <div className="block">
                <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                  Wallet Address
                </p>
                <Field
                  type="text"
                  name="address"
                  className=" rounded p-4 w-full bg-black border border-white text-white"
                  placeholder="******"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  // validate={validatePassword}
                />
                <div className="text-red-400">
                  {errors.address && touched.password && errors.address}
                </div>
              </div>
              <p className="text-xs text-gray-200 mt-2 text-left">
                Please enter a valid wallet address to avoid loss of funds
              </p>
              <div className="form-submit">
                {buttonState && (
                  <div
                    className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                text-white bg-blue-700 hover:bg-blue-900 flex justify-center items-center"
                  >
                    <CircularProgress size={25} />
                  </div>
                )}
                {!buttonState && (
                  <input
                    className=" rounded-md cursor-pointer pt-2.5 pr-3.5 pb-2.5 pl-3.5 bg-green-500 mt-4 w-full text-white hover:bg-green-700"
                    type="submit"
                    disabled={buttonState}
                    value="Send"
                  />
                )}
              </div>
              <div className="text-red-400 mt-4">
                {/* {authError ? <p>{authError} </p> : null} */}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
