import CurrencySelect from "../../components/CurrencySelect";
import Layout from "../../components/Layout";
import stakingImg from "./staking.png";
export default function Staking() {
  return (
    <Layout>
      <main
        className="page-content flex flex-col items-center"
        aria-label="Content"
      >
        <div className="bg-black pt-16 text-white w-full">
          <div className="bg-transparent relative mr-auto ml-auto">
            <div
              className="bg-transparent pt-16 pr-4 pb-16 pl-4 mr-auto ml-auto sm:max-w-xl md:max-w-full lg:px-8 md:px-24
            lg:max-w-screen-xl lg:py-20"
            >
              <div className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row">
                <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
                  <div className="mb-6 max-w-xl">
                    <div className=" font-bold leading-tight text-white   mb-6">
                      <p className="text-3xl md:text-5xl font-bold mb-6 sm:text-4xl sm:leading-none">
                        Earn rewards with Paxcrypt Staking
                      </p>
                    </div>
                    <p className="bg-transparent text-base mb-6 md:text-lg">
                      With Paxcrypt Staking, you can participate in the
                      blockchain ecosystem and receive rewards on your crypto.
                      Stake any amount of crypto securely on Paxcrypt.
                    </p>
                    <a
                      href="/dashboard"
                      className="border-0 rounded-lg mt-4 py-3 px-8 text-black bg-white  font-bold text-center"
                    >
                      Start Staking
                    </a>
                  </div>
                </div>
                <div className="flex items-center justify-center lg:w-1/2">
                  <form className="form flex border border-white flex-col bg-black w-80 mt-4 rotate-6 text-left  rounded-lg p-6 py-4 text-white mx-auto">
                    {/* <h3 className="text-center text-lg font-bold">Bronze</h3> */}
                    <p className="text-center font-bold mb-2">
                      Become a blockchain validator
                    </p>
                    <div className="w-full">
                      <CurrencySelect seletedCurrency={"btc"} />
                      <p className="text-gray-200 font-medium text-xs mt-0 mr-0 mb-0 ml-0 block">
                        Balance: $10,000
                      </p>
                    </div>
                    <div className="w-full">
                      <p className="text-gray-700 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                        Amount
                      </p>
                      <input
                        type="number"
                        name="amount"
                        className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                        placeholder="$100"
                        required
                        id="outlined-basic"
                      />
                      <p className="text-xs text-right mb-4">
                        $10,000 - $50,000
                      </p>
                    </div>
                    <div className="text-sm">
                      <div className="flex justify-between items-baseline">
                        <p>APY</p>
                        <p className="text-green-500 text-xl">20%</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Daily Earnings</p>
                        <p className="text-green-400">+2%</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Monthly Earnings</p>
                        <p className="text-green-400">+7%</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Yearly Earnings</p>
                        <p className="text-green-400">+23%</p>
                      </div>
                    </div>

                    <a
                      href="/dashboard"
                      className="border-0 rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-700 hover:bg-blue-900  text-center"
                    >
                      Stake
                    </a>
                  </form>
                  {/* <div className="w-5/12 ml-8 z-10">
                    <img
                      src="https://nexo.io/media/pages/referral/3e32876041-1658919160/increased-limits-x2.png"
                      className="object-cover"
                      alt="sbf"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center md:items-stretch md:flex-row md:justify-between gap-3  py-8 mt-24 md:px-20 ">
          <div class="w-10/12 md:w-1/2 bg-gray-100 rounded-lg px-6 py-8 md:p-12">
            <h3 class="text-xl mb:text-3xl font-bold mb-8 text">
              What is Staking?
            </h3>
            <div className="flex flex-col md:flex-row mb-8">
              <div class="w-64 mr-2 mb-2 md:mb-0">
                <picture class="">
                  <source
                    srcset="//images.ctfassets.net/jg6lo9a2ukvr/5HIcippfXYz1Z5NffwdoIC/ed3eafb1300091c39f90702d4e6888b3/proof_of_stake.svg"
                    type="image/svg+xml"
                  />
                  <img
                    class=""
                    src="//images.ctfassets.net/jg6lo9a2ukvr/5HIcippfXYz1Z5NffwdoIC/ed3eafb1300091c39f90702d4e6888b3/proof_of_stake.svg"
                    alt="Proof of stake"
                  />
                </picture>
              </div>
              <div class="">
                <h5 class="font-semibold text-xl mb-4">Proof of Stake</h5>
                <p color="#010304" class="sc-1379d271-0 beSnAX">
                  <span>
                    Staking is an essential feature of proof of stake (PoS)
                    protocols. Large PoS protocols including Polygon, Solana,
                    and Polkadot allow users to stake their native tokens and
                    earn rewards.
                    <br />
                  </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col md:flex-row mb-8">
              <div class="w-64 mr-6  mb-2 md:mb-0">
                <picture class="">
                  <source
                    srcset="//images.ctfassets.net/jg6lo9a2ukvr/6m8UGFikhlv0LgVx4QglKE/d3d954d90ea4df33a58eb2c7ad0ce619/validators.svg"
                    type="image/svg+xml"
                  />
                  <img
                    class=""
                    src="//images.ctfassets.net/jg6lo9a2ukvr/6m8UGFikhlv0LgVx4QglKE/d3d954d90ea4df33a58eb2c7ad0ce619/validators.svg"
                    alt="Incentive for validators"
                  />
                </picture>
              </div>
              <div class="">
                <h5 class="font-semibold text-xl mb-4">
                  An incentive for validators
                </h5>
                <p color="#010304" class="sc-1379d271-0 beSnAX">
                  <span>
                    Blockchain node operators must pledge their tokens, also
                    known as staking, to a network in order to be selected as a
                    block validator. As a reward for correctly adding valid
                    blocks to the blockchain, node operators receive newly
                    minted tokens as rewards, known as staking rewards.
                  </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col md:flex-row">
              <div class="w-64 mr-6 mb-2 md:mb-0">
                <picture class="sc-aae13d79-0 cmySyP">
                  <source
                    srcset="//images.ctfassets.net/jg6lo9a2ukvr/2KPAUG1GVmmPikGWG1jUFU/bce87dd37347a9cc1166e1414a109084/slashing.svg"
                    type="image/svg+xml"
                  />
                  <img
                    class=""
                    src="//images.ctfassets.net/jg6lo9a2ukvr/2KPAUG1GVmmPikGWG1jUFU/bce87dd37347a9cc1166e1414a109084/slashing.svg"
                    alt="Slashing "
                  />
                </picture>
              </div>
              <div class="sc-feb4e2a1-3 fRUKgK">
                <h5 class="font-semibold text-xl mb-4">Slashing</h5>
                <p color="#010304" class="sc-1379d271-0 beSnAX">
                  <span>
                    Node operators who perform invalid functions (eg. adding a
                    corrupted block to the blockchain) could lose a portion of
                    their pledged tokens, a function known as slashing. Our
                    staking validators are highly effective, and we guarantee to
                    reimburse any losses (slashes) due to our validation
                    process.
                    <br />
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div class="w-10/12 md:w-1/2 bg-gray-100 rounded-lg px-6 py-8 md:p-12">
            <h3 class="text-xl mb:text-3xl font-bold mb-4 text">
              Why stake your crypto?
            </h3>
            <p color="#010304" class="sc-1379d271-0 hYkgFF">
              <span>
                Staking can be complicated because it typically takes place on a
                decentralized platform, and requires technical skills to manage
                your crypto wallet, validate blocks, and claim rewards. Paxcrypt
                Staking simplifies the process for you and allows customers to
                generate crypto rewards from a decentralized system on a secure
                and regulated platform. It’s the best of both worlds: the
                benefit of decentralization with the power of Paxcrypt.
                <br />
              </span>
            </p>
            <div class="flex items-center my-6">
              <div class="mr-4">
                <picture class="sc-aae13d79-0 cmySyP">
                  <source
                    srcset="//images.ctfassets.net/jg6lo9a2ukvr/6AO3UqoK0HZSiQqNWaQQAZ/3d442f3054cc42a671c531c878021101/spend.svg"
                    type="image/svg+xml"
                  />
                  <img
                    class=""
                    src="//images.ctfassets.net/jg6lo9a2ukvr/6AO3UqoK0HZSiQqNWaQQAZ/3d442f3054cc42a671c531c878021101/spend.svg"
                    alt="Spend Icon"
                  />
                </picture>
              </div>
              <div class="sc-feb4e2a1-3 sc-b651f535-6 KLekW jJaheX">
                <h5 class="font-bold">Earn Airdrops</h5>
              </div>
            </div>
            <div class="flex items-center my-6">
              <div class="mr-4">
                <picture class="sc-aae13d79-0 cmySyP">
                  <source
                    srcset="//images.ctfassets.net/jg6lo9a2ukvr/GGOA4Cg8fAz0rnYFbN8eh/2b5ce722a618704e14e3b3cdc39d0b7e/trade.svg"
                    type="image/svg+xml"
                  />
                  <img
                    class=""
                    src="//images.ctfassets.net/jg6lo9a2ukvr/GGOA4Cg8fAz0rnYFbN8eh/2b5ce722a618704e14e3b3cdc39d0b7e/trade.svg"
                    alt="Trade Icon"
                  />
                </picture>
              </div>
              <div class="sc-feb4e2a1-3 sc-b651f535-6 KLekW jJaheX">
                <h5 class="font-bold">Trade</h5>
              </div>
            </div>
            <div class="flex items-center my-6">
              <div class="mr-4">
                <picture class="sc-aae13d79-0 cmySyP">
                  <source
                    srcset="//images.ctfassets.net/jg6lo9a2ukvr/5q81pZccYk9xIy81aKGrHN/4e213e04e64d5ecaff864fe86a051357/save.svg"
                    type="image/svg+xml"
                  />
                  <img
                    class=""
                    src="//images.ctfassets.net/jg6lo9a2ukvr/5q81pZccYk9xIy81aKGrHN/4e213e04e64d5ecaff864fe86a051357/save.svg"
                    alt="Save Icon"
                  />
                </picture>
              </div>
              <div class="sc-feb4e2a1-3 sc-b651f535-6 KLekW jJaheX">
                <h5 class="font-bold">Save</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <section className="py-16 text-center">
            <h1 className="font-bold text-3xl text-center mb-4">
              Choose an Asset and start staking
            </h1>
            <div className="flex flex-col md:flex-row gap-3">
              <form className="form flex flex-col bg-black w-80 mt-8 text-left  rounded-lg p-6 py-4 text-white mx-auto">
                <p className="text-center font-bold mb-2">
                  Become a Ethereum validator
                </p>
                <div className="w-full">
                  <CurrencySelect seletedCurrency={"eth"} />
                  <p className="text-gray-200 font-medium text-xs mt-0 mr-0 mb-0 ml-0 block">
                    Balance: $10,000
                  </p>
                </div>
                <div className="w-full">
                  <p className="text-gray-700 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                    Amount
                  </p>
                  <input
                    type="number"
                    name="amount"
                    className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                    placeholder="$100"
                    required
                    id="outlined-basic"
                  />
                  <p className="text-xs text-right mb-4">$10,000 - $50,000</p>
                </div>
                <div className="text-sm">
                  <div className="flex justify-between items-baseline">
                    <p>APY</p>
                    <p className="text-green-500 text-xl">20%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Daily Earnings</p>
                    <p className="text-green-400">+2%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Monthly Earnings</p>
                    <p className="text-green-400">+7%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Yearly Earnings</p>
                    <p className="text-green-400">+23%</p>
                  </div>
                </div>

                <a
                  href="/dashboard"
                  className="border-0 rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-700 hover:bg-blue-900  text-center"
                >
                  Stake
                </a>
              </form>
              <form className="form flex flex-col bg-black w-80 mt-8 text-left  rounded-lg p-6 py-4 text-white mx-auto">
                <p className="text-center font-bold mb-2">
                  Become an LTC validator
                </p>
                <div className="w-full">
                  <CurrencySelect seletedCurrency={"ltc"} />
                  <p className="text-gray-200 font-medium text-xs mt-0 mr-0 mb-0 ml-0 block">
                    Balance: $10,000
                  </p>
                </div>
                <div className="w-full">
                  <p className="text-gray-700 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                    Amount
                  </p>
                  <input
                    type="number"
                    name="amount"
                    className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                    placeholder="$100"
                    required
                    id="outlined-basic"
                  />
                  <p className="text-xs text-right mb-4">$10,000 - $50,000</p>
                </div>
                <div className="text-sm">
                  <div className="flex justify-between items-baseline">
                    <p>APY</p>
                    <p className="text-green-500 text-xl">20%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Daily Earnings</p>
                    <p className="text-green-400">+2%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Monthly Earnings</p>
                    <p className="text-green-400">+7%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Yearly Earnings</p>
                    <p className="text-green-400">+23%</p>
                  </div>
                </div>

                <a
                  href="/dashboard"
                  className="border-0 rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-700 hover:bg-blue-900  text-center"
                >
                  Stake
                </a>
              </form>
              <form className="form flex flex-col bg-black w-80 mt-8 text-left  rounded-lg p-6 py-4 text-white mx-auto">
                <p className="text-center font-bold mb-2">
                  Become a Bitcoin validator
                </p>
                <div className="w-full">
                  <CurrencySelect seletedCurrency={"btc"} />
                  <p className="text-gray-200 font-medium text-xs mt-0 mr-0 mb-0 ml-0 block">
                    Balance: $10,000
                  </p>
                </div>
                <div className="w-full">
                  <p className="text-gray-700 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                    Amount
                  </p>
                  <input
                    type="number"
                    name="amount"
                    className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                    placeholder="$100"
                    required
                    id="outlined-basic"
                  />
                  <p className="text-xs text-right mb-4">$10,000 - $50,000</p>
                </div>
                <div className="text-sm">
                  <div className="flex justify-between items-baseline">
                    <p>APY</p>
                    <p className="text-green-500 text-xl">20%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Daily Earnings</p>
                    <p className="text-green-400">+2%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Monthly Earnings</p>
                    <p className="text-green-400">+7%</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Yearly Earnings</p>
                    <p className="text-green-400">+23%</p>
                  </div>
                </div>

                <a
                  href="/dashboard"
                  className="border-0 rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-700 hover:bg-blue-900  text-center"
                >
                  Stake
                </a>
              </form>
            </div>
          </section>
          <section className="pt-8 pb-8 border-bottom text-center text-md-left">
            <div className="flex flex-col md:flex-row  items-center justify-between">
              <div className="w-10/12 md:w-1/2 text-left">
                <h2 className="text-4xl mb-3 font-bold ">
                  What are some of the benefits?
                </h2>
                <p className="text-gray-700"></p>
                <ul className="ul-bullet">
                  <li>
                    One of the major benefits for staking coins is that it
                    removes the need for continuously purchasing expensive
                    hardware and consuming energy.{" "}
                  </li>
                  <li>
                    The system offers guaranteed returns and a predictable
                    source of income unlike the proof-of-work system where coins
                    are rewarded through a mathmatetical process with a low
                    probability of paying out. Another benefit is that the value
                    of your staked coins doesn't depreciate unlike with ASICs
                    and other mining hardware. Staked coins are only affected by
                    market price fluctuations.
                  </li>
                </ul>
                <p></p>
              </div>
              <div className="w-10/12 md:w-1/2">
                <img
                  className="lg:h-auto
                    xl:mr-24 md:max-w-lg btn- w-full mr-auto ml-auto"
                  src={stakingImg}
                  alt="banner"
                />
              </div>
            </div>
          </section>
          <section className="pt-8 pb-8 border-bottom text-center text-md-left">
            <div className="flex flex-col md:flex-row-reverse items-center justify-between">
              <div className="w-10/12 md:w-1/2 text-left">
                <h3 className="text-4xl mb-3 font-bold">How does it work?</h3>
                <p className="text-gray-700">
                  The standard methods for staking are usually holding coins in
                  your wallet or locking them in a smart contract (masternodes).
                  Some coins added randomness to the process of staking and
                  voting so that bad players have a hard time manipulating
                  outcomes. The process can be similar to a lottery in which the
                  number of crypto coins you hold is equivalent to holding a
                  given number of lottery tickets. Staking systems can also
                  allow delegation in which each individual delegates their
                  voting rights and earned income to a trusted party. Those
                  delegates then earn all the rewards for block validation and
                  pay their loyal supporters some form of dividends in return
                  for their vote.
                </p>
              </div>
              <div className="w-10/12 md:w-1/2 text-left">
                <img
                  className="lg:h-auto
                    xl:mr-24 md:max-w-lg btn- w-full mr-auto ml-auto"
                  src={stakingImg}
                  alt="banner"
                />
              </div>
            </div>
          </section>
          <section className="p-8 text-left border-t">
            <div className="flex justify-between">
              <div className="w-full">
                <h3 className="font-bold text-2xl mb-3 ">
                  Always do your own research
                </h3>
                <p className="text-gray-700">
                  Choosing the right coin to stake, is both a numbers game and a
                  gut feeling. If you choose to begin staking, definitely start
                  by experimenting with minimum amounts with particular staking
                  protocols and staking rewards. Always remember to pick a
                  project that resonates with you and one that you expect will
                  be around far into the future. After all, by staking, you’re
                  helping to make that project become a success.
                </p>
              </div>
            </div>
          </section>
        </div>
        <div class="py-20 px-8 bg-blue-500 text-white text-center">
          <p className="text-4xl mb-4 font-bold">
            Be In Charge Of Your Crypto{" "}
          </p>

          <p className="text-2xl mb-12">
            Paxcrypt is an ultimate solution for modern crypto management. You
            can do it all from the comfort of one single app. Earn with staking,
            Purchase, Instantly Exchange at best rates, get crypto loans, add
            extra security layer with Multisignature, and the list goes on.
          </p>

          <a class="py-4 px-8 bg-white text-blue-500 rounded" href="/signup">
            Get Started
          </a>
        </div>
      </main>
    </Layout>
  );
}
