import React, { useState, useRef } from "react";
import Container from "@mui/material/Container";
import { useAuth } from "../../context/auth";
import { CircularProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import NotificationSystem from "react-notification-system";
import LoadingScreen from "../LoadingScreen";
import FormError from "../FormError";
import { usePDF } from "react-to-pdf";
import { formatter } from "../../utils/currency-formatter";

export default function OTC() {
  const notificationSystem = useRef();
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

  const [buttonState, setButtonState] = useState(false);
  const [hasGeneratedInvoice, setHasGeneratedInvoice] = useState(false);
  const [asset, setAsset] = useState("");
  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState("");
  const { user, loading } = useAuth();

  const sendKYC = async (values) => {
    setAsset(values.asset);
    setAmount(values.amount);
    setAddress(values.address);
    setHasGeneratedInvoice(true);
  };
  const reset = async () => {
    setAsset("");
    setAmount(0);
    setAddress("");
    setHasGeneratedInvoice(false);
    setButtonState(false);
  };

  if (loading) return <LoadingScreen loading={loading} />;
  return (
    <>
      <NotificationSystem ref={notificationSystem} />

      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
        className="flex justify-center items-center px-0 mb-36"
      >
        {hasGeneratedInvoice ? (
          <div className="w-full">
            <div className="flex items-center gap-2">
              <button
                onClick={() => toPDF()}
                className="text-white hover:bg-blue-700 text-base cursor-pointer bg-blue-500 p-3 mb-4 rounded-md flex items-center justify-center"
              >
                Download Invoice
              </button>
              <button
                onClick={() => reset()}
                className="text-white hover:bg-red-700 text-base cursor-pointer bg-red-500 p-3 mb-4 rounded-md flex items-center justify-center"
              >
                Cancel
              </button>
            </div>
            <div className="bg-white">
              <div
                ref={targetRef}
                id="invoiceCapture"
                className="py-8 px-4 md:p-8"
              >
                <div className="flex justify-between pb-5 mb-5 border-b border-b-gray-200">
                  <div>
                    <div className="flex flex-col">
                      <img
                        className="w-10 md:w-14 mr-2"
                        src="/android-chrome-192x192.png"
                        alt="logo"
                      />
                      <h2 className="text-2xl font-semibold text-blue-500">
                        Paxcrypt
                      </h2>
                    </div>
                    {/* <p>Address</p>
                    <p>City</p>
                    <p>United States</p> */}
                  </div>
                  <h2 className="text-2xl md:text-4xl font-semibold text-black">
                    OTC Invoice
                  </h2>
                </div>
                <div className="flex flex-row justify-between items-start ">
                  <div className="">
                    <h2 className="font-semibold">Bill To:</h2>
                    <h4 className="">
                      {user.first_name + " " + user.last_name ||
                        "John Uberbacher"}
                    </h4>
                    <h4 className="">{address || "Address"}</h4>
                    {/* <h4 className="">United States</h4> */}
                  </div>
                  <div className="text-end ms-4">
                    <h6 className=" mt-1 mb-2">
                      Invoice#:{" "}
                      {"100" +
                        Math.floor(
                          Math.random() * (999 - 100 + 1) + 100
                        ).toString()}
                    </h6>
                    <h6 className=" mt-1 mb-2">
                      Invoice Date: {new Date().toDateString()}
                    </h6>
                    {/* <h6 className=" mt-1 mb-2">
                      Due date: {new Date().toDateString()}
                    </h6> */}
                  </div>
                </div>
                <div className="mt-5 text-sm md:text-base">
                  <div className="bg-gray-600  flex text-white p-1 py-3">
                    <div className="w-2/6 md:w-3/6">Item Description</div>
                    <div className="w-1/6">QTY</div>
                    <div className="w-1/6">Rate</div>
                    <div className="w-1/6">Amount</div>
                  </div>
                  <div className="flex p-1 py-3 border-b border-b-gray-200">
                    <div className="uppercase w-2/6 md:w-3/6">
                      {asset || "USDT"}
                    </div>
                    <div className="w-1/6">{amount || "00"}</div>
                    <div className="w-1/6">1</div>
                    <div className="w-1/6">
                      {formatter.format(amount) || "00"}
                    </div>
                  </div>
                  <div className="flex p-1 py-2">
                    <div className="w-2/6 md:w-3/6"></div>
                    <div className="w-1/6"></div>
                    <div className="w-1/6">Sub Total</div>
                    <div className="w-1/6">
                      {formatter.format(amount) || "20000.00"}
                    </div>
                  </div>
                  <div className="flex p-1 py-2 bg-gray-100">
                    <div className="w-2/6 md:w-3/6"></div>
                    <div className="w-1/6"></div>
                    <div className="w-1/6">Total</div>
                    <div className="w-1/6">
                      {formatter.format(amount) || "20000.00"}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h2 className="font-semibold">Payment details:</h2>
                  <h4 className="">Account Name: Kristine M Srigley </h4>
                  <h4 className="">Bank Name: TD bank Bank </h4>
                  <h4 className="">Account Number: 4435061588 </h4>
                  <h4 className="">Routing: 026013673 </h4>
                  <h4 className="">
                    Bank address: TD Bank Elmhurst 7801 Queens Blvd Elmhurst, NY
                    11373
                  </h4>
                  <h4 className="">
                    Beneficiary address: T60 MISTY MEADOW DR RENOYLDS, PA 17569
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={{
              asset: "",
              amount: "",
              address: "",
            }}
            onSubmit={(values) => {
              setButtonState(true);
              sendKYC(values);
            }}
          >
            {({ errors, touched, handleChange, handleBlur, setFieldValue }) => (
              <Form className="form flex flex-col p-8 bg-black border border-gray-500 rounded-sm w-full">
                <h3 className="text-white text-lg">OTC Form</h3>
                <h3 className="text-white text-base">
                  Please enter the details of your OTC purchase below
                </h3>
                <div className="flex flex-col mt-4 gap-3 items-between">
                  <div className="lg:w-2/4  ">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Asset
                    </p>
                    <Field
                      type="text"
                      required
                      name="asset"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="USDT"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError error={errors.asset} touched={touched.asset} />
                  </div>
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Amount($)
                    </p>
                    <Field
                      required
                      type="number"
                      name="amount"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="500000"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError error={errors.amount} touched={touched.amount} />
                  </div>
                </div>
                <div className="flex flex-col gap-3 mt-4 items-between">
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Billing Address
                    </p>
                    <Field
                      type="text"
                      required
                      name="address"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="4 College Ave, Somerville, Maine, 02144"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError
                      error={errors.address}
                      touched={touched.address}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                                text-white bg-blue-500 hover:bg-blue-700 cursor-pointer"
                >
                  {buttonState ? (
                    <CircularProgress className="text-white" size={25} />
                  ) : (
                    "Generate Invoice"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </>
  );
}
