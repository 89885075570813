import Layout from "../../components/Layout";

export default function LicensesAndRegisterations() {
  return (
    <Layout>
      <header
        className="bg-contain bg-no-repeat bg-center"
        block-name="licenses-header"
        style={{backgroundImage:" url(https://nexo.io/media/pages/storage/images/ba79788cbc-1649751571/laurel-wreath.svg)"}}
      >
        <div className="container py-32  sm:pb-0 sm:mt-64 mx-auto sm:mb-32 text-center max-w-xl md:max-w-3xl">
          <h1 className="heading-1 mb-24">
            <span className="text-accent-500">Licenses</span> &amp; Registrations{" "}
          </h1>
          <p className="d-subheading-1">
            In order to ensure the provision of their portfolio of services in
            full compliance with all applicable global and local regulations and
            standards, the Paxcrypt companies hold licenses and registrations in
            numerous jurisdictions worldwide, and are constantly bringing their
            operations in line with newly adopted legislative changes.{" "}
          </p>
        </div>
      </header>
      <section className="mt-32 mb-64 md:my-72" block-name="licenses-list">
        <div className="container mx-auto">
          <div className="flex flex-wrap s-border-pull sm:var-items-2 md:var-items-3">
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="240"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/2ffe9a0852-1631610934/logo-financial-crimes-enforcement-network.png"
                    width="240"
                  />
                </div>
                <p className="d-label-1 mb-12">United States </p>
                <h2 className="d-heading-3 mb-4">
                  Financial Crimes Enforcement Network{" "}
                </h2>
                <p className="d-body-text-1">
                  Money Service Business Registration{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">31000201460825 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.fincen.gov/msb-registrant-search"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.fincen.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="99"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/d3eab92c57-1631610933/logo-alabama.png"
                    width="97"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Alabama </p>
                <h2 className="d-heading-3 mb-4">State Banking Department </h2>
                <p className="d-body-text-1">Consumer Credit License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MC-22897 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.banking.alabama.gov/LicenseesBOL.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.banking.alabama.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="1200"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/a852684edf-1640002028/logo-alabama-security-commission.png"
                    width="1200"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Alabama </p>
                <h2 className="d-heading-3 mb-4">Alabama Securities Commission </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">#859 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.asc.alabama.gov"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.asc.alabama.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="144"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/2a7bead2f4-1662533852/logo-alaska.png"
                    width="144"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Alaska </p>
                <h2 className="d-heading-3 mb-4">
                  Division of Banking and Securities{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">AKMT-015420 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.commerce.alaska.gov/cbp/businesslicense/search/License"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.commerce.alaska.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="125"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/1a399c1e18-1631610933/logo-arizona.png"
                    width="430"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Arizona </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Insurance and Financial Institutions{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MT-1024418 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://elicense.az.gov/ARDC_SearchDetail?id=a0Y8y000000NhOWEA0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.difi.az.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="125"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/1a399c1e18-1631610933/logo-arizona.png"
                    width="430"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Arizona </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Insurance and Financial Institutions{" "}
                </h2>
                <p className="d-body-text-1">Consumer Lender License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">CL-1012734 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://elicense.az.gov/ARDC_SearchDetail?id=a0Yt000000RzhAqEAJ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.difi.az.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="200"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/97d0db25a4-1635780273/logo-arkansas.png"
                    width="200"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Arkansas </p>
                <h2 className="d-heading-3 mb-4">
                  Arkansas Securities Department{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">124638 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://securities.arkansas.gov/money-services-2/7678-2/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.securities.arkansas.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="112"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/fd30768a3e-1631610934/logo-dfpi.png"
                    width="933"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, California </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Financial Protection and Innovation{" "}
                </h2>
                <p className="d-body-text-1">Financing Law License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">60DBO-109416 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://portal.dfpi.ca.gov/csp?id=ssp_license_search"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dfpi.ca.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="77"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/2e873eb81e-1631610934/logo-dc-gov.png"
                    width="161"
                  />
                </div>
                <p className="d-label-1 mb-12">
                  United States, District of Columbia{" "}
                </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Licensing and Consumer Protection{" "}
                </h2>
                <p className="d-body-text-1">Money Lender License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">ML-1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://dlcp.dc.gov"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dlcp.dc.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="300"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/2fd83bdd55-1631610934/logo-delaware.png"
                    width="300"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Delaware </p>
                <h2 className="d-heading-3 mb-4">
                  Office of the State Bank Commissioner{" "}
                </h2>
                <p className="d-body-text-1">Lender License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">32237 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://banking.delaware.gov/non-depository-institutions/#:~:text=and%20Money%20Transmitter-,Licensed%20Lender,-Mortgage%20Loan%20Broker"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.banking.delaware.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="201"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/1ad6784f33-1635420912/logo-georgia.png"
                    width="200"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Georgia </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Banking and Finance{" "}
                </h2>
                <p className="d-body-text-1">
                  Seller of Payment Instruments License{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://dbf.georgia.gov/license-certificate-0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dbf.georgia.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="136"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/198f2e720b-1631610934/logo-idaho.svg"
                    width="252"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Idaho </p>
                <h2 className="d-heading-3 mb-4">Department of Finance </h2>
                <p className="d-body-text-1">Regulated Lender License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">RRL-10387 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.finance.idaho.gov/licensee-search/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.finance.idaho.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="136"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/198f2e720b-1631610934/logo-idaho.svg"
                    width="252"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Idaho </p>
                <h2 className="d-heading-3 mb-4">Department of Finance </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MTL-312 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.finance.idaho.gov/licensee-search/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.finance.idaho.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="58"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/6ff72add4c-1631610934/logo-idfpr.png"
                    width="200"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Illinois </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Financial &amp; Professional Regulation{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MT.0000435 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://online-obre.micropact.com/Lookup/LicenseLookup.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.idfpr.illinois.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="58"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/6ff72add4c-1631610934/logo-idfpr.png"
                    width="200"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Illinois </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Financial &amp; Professional Regulation{" "}
                </h2>
                <p className="d-body-text-1">Consumer Installment Loan License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">CI.0004661-H </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://online-obre.micropact.com/Lookup/LicenseLookup.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.idfpr.illinois.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="66"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/2c24331e94-1643022982/logo-idob.png"
                    width="277"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Iowa </p>
                <h2 className="d-heading-3 mb-4">Iowa Division of Banking </h2>
                <p className="d-body-text-1">Money Service License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">2022-0007 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.idob.state.ia.us/public/fin_license/licenseVerify.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.idob.state.ia.us{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="184"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/8b0d2525c6-1631610934/logo-kansas.png"
                    width="437"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Kansas </p>
                <h2 className="d-heading-3 mb-4">
                  Office of the State Bank Commissioner{" "}
                </h2>
                <p className="d-body-text-1">Supervised Loan License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">SL.0026808 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://online.osbckansas.org/Lookup/LicenseLookup.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.osbckansas.org{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="184"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/8b0d2525c6-1631610934/logo-kansas.png"
                    width="437"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Kansas </p>
                <h2 className="d-heading-3 mb-4">
                  Office of the State Bank Commissioner{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MT.0000217 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://online.osbckansas.org/Lookup/LicenseLookup.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.osbckansas.org{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="52"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/5e23fa330b-1636552997/logo-maryland-gov.png"
                    width="324"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Maryland </p>
                <h2 className="d-heading-3 mb-4">
                  Commissioner of Financial Regulation{" "}
                </h2>
                <p className="d-body-text-1">Installment Loan License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">03-1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.dllr.state.md.us/finance/industry/licsearch.shtml"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.maryland.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="52"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/5e23fa330b-1636552997/logo-maryland-gov.png"
                    width="324"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Maryland </p>
                <h2 className="d-heading-3 mb-4">
                  Commissioner of Financial Regulation{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.dllr.state.md.us/finance/industry/licsearch.shtml"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.maryland.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="65"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/2d89b253cc-1631610934/logo-department-of-commerce.png"
                    width="400"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Minnesota </p>
                <h2 className="d-heading-3 mb-4">Department of Commerce </h2>
                <p className="d-body-text-1">Regulated Loan Company License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MN-RL-1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://mn.gov/commerce/consumers/tips-tools/license-lookup.jsp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.mn.gov/commerce{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="65"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/2d89b253cc-1631610934/logo-department-of-commerce.png"
                    width="400"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Minnesota </p>
                <h2 className="d-heading-3 mb-4">Department of Commerce </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MN-MT-1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://mn.gov/commerce/consumers/tips-tools/license-lookup.jsp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.mn.gov/commerce{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="64"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/08eaba8c17-1631610934/logo-mississippi.png"
                    width="459"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Mississippi </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Banking and Consumer Finance{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://dbcf.ms.gov"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dbcf.ms.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="138"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/df41921a98-1631610934/logo-missouri-df.png"
                    width="536"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Missouri </p>
                <h2 className="d-heading-3 mb-4">Division of Finance </h2>
                <p className="d-body-text-1">
                  Sale of Checks &amp; Money Transmitter License{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MO-22-8911 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://finance.mo.gov/licensee/search?sort_type=name&amp;sort_asc_desc=asc&amp;licensee_type=ALL&amp;search_query=Paxcrypt+Financial+LLC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.finance.mo.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="240"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/141602dddc-1634547676/mda240x240.jpg"
                    width="240"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Montana </p>
                <h2 className="d-heading-3 mb-4">
                  Division of Banking and Financial Institutions{" "}
                </h2>
                <p className="d-body-text-1">Consumer Loan License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://banking.mt.gov"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.banking.mt.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="70"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/5442145972-1631610934/logo-new-hampshire-bd.png"
                    width="320"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, New Hampshire </p>
                <h2 className="d-heading-3 mb-4">Banking Department </h2>
                <p className="d-body-text-1">Small Loan Lender License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">23920-SM </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.banking.nh.gov/consumer-assistance/virtual-currencies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.banking.nh.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="70"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/5442145972-1631610934/logo-new-hampshire-bd.png"
                    width="320"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, New Hampshire </p>
                <h2 className="d-heading-3 mb-4">Banking Department </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">24079-MT </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.banking.nh.gov/consumer-assistance/virtual-currencies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.banking.nh.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="326"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/e7dea366b3-1651569007/nmrld-logo.png"
                    width="801"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, New Mexico </p>
                <h2 className="d-heading-3 mb-4">
                  New Mexico Financial Institutions Division{" "}
                </h2>
                <p className="d-body-text-1">Small Loan Company License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">2350 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.rld.nm.gov/financial-institutions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.rld.nm.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="326"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/e7dea366b3-1651569007/nmrld-logo.png"
                    width="801"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, New Mexico </p>
                <h2 className="d-heading-3 mb-4">
                  New Mexico Financial Institutions Division{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">N/A </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.rld.nm.gov/financial-institutions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.rld.nm.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="114"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/93c0ae12eb-1641563536/logo-north-dakota.png"
                    width="528"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, North Dakota </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Financial Institutions{" "}
                </h2>
                <p className="d-body-text-1">Money Broker License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MB103929 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.nd.gov/dfi/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.nd.gov/dfi{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="114"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/93c0ae12eb-1641563536/logo-north-dakota.png"
                    width="528"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, North Dakota </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Financial Institutions{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">MT103933 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.nd.gov/dfi/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.nd.gov/dfi{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="115"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/0f0cc0162f-1657696529/logo-okdocc.jpg"
                    width="390"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Oklahoma </p>
                <h2 className="d-heading-3 mb-4">Department of Consumer Credit </h2>
                <p className="d-body-text-1">Small Lender License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">OSL00174 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.ok.gov/okdocc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.ok.gov/okdocc{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="200"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/ff662b408d-1631610934/logo-oregon.svg"
                    width="200"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Oregon </p>
                <h2 className="d-heading-3 mb-4">
                  Division of Financial Regulation{" "}
                </h2>
                <p className="d-body-text-1">Consumer Finance License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://dfr.oregon.gov/help/complaints-licenses/Pages/check-license.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dfr.oregon.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="200"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/ff662b408d-1631610934/logo-oregon.svg"
                    width="200"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Oregon </p>
                <h2 className="d-heading-3 mb-4">
                  Division of Financial Regulation{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://dfr.oregon.gov/help/complaints-licenses/Pages/check-license.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dfr.oregon.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="89"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/60d32c0875-1636023089/logo-pennsylvania.png"
                    width="320"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Pennsylvania </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Banking and Securities{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">93060 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.dobs.pa.gov/Pages/default.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dobs.pa.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="318"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/14ccf56abc-1656657520/logo-scag.png"
                    width="957"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, South Carolina </p>
                <h2 className="d-heading-3 mb-4">
                  Office of the Attorney General{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">N/A </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.scag.gov/inside-the-office/legal-services-division/money-services/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.scag.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="107"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/7051e35de6-1637682840/logo-dlrl.png"
                    width="360"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, South Dakota </p>
                <h2 className="d-heading-3 mb-4">Division of Banking </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">1898755.MT </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://dlr.sd.gov/banking/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dlr.sd.gov/banking{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="718"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/408d0b370e-1655293031/seal_tennessee.png"
                    width="715"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Tennessee </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Financial Institutions{" "}
                </h2>
                <p className="d-body-text-1">
                  Industrial Loan and Thrift Company Registration{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.tn.gov/tdfi.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.tn.gov/tdfi{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="139"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/b54b720bcf-1631610934/logo-dfi.png"
                    width="156"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Utah </p>
                <h2 className="d-heading-3 mb-4">
                  Department of Financial Institutions{" "}
                </h2>
                <p className="d-body-text-1">Consumer Credit Notification </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">N/A </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://dfi.utah.gov"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dfi.utah.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="138"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/3befe6664f-1646222458/logo-wvgov.png"
                    width="200"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, West Virginia </p>
                <h2 className="d-heading-3 mb-4">
                  Division of Financial Institutions{" "}
                </h2>
                <p className="d-body-text-1">Money Transmitter License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">WVMT-1898755 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://dfi.wv.gov/Pages/default.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.dfi.wv.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="161"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/7a6c8b80bd-1631610934/logo-wyoming.png"
                    width="308"
                  />
                </div>
                <p className="d-label-1 mb-12">United States, Wyoming </p>
                <h2 className="d-heading-3 mb-4">Department of Audit </h2>
                <p className="d-body-text-1">Supervised Lender License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">CL-4219 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial LLC </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://audit.wyo.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.audit.wyo.gov{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32"></div>
                <p className="d-label-1 mb-12">Australia </p>
                <h2 className="d-heading-3 mb-4">
                  Securities and Investment Commission{" "}
                </h2>
                <p className="d-body-text-1">Registration as Foreign Company </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">647056540 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Capital Inc. </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=114l2od7hy_4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.asic.gov.au{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="168"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/7db0f863f7-1631610934/logo-fintrac-canafe.png"
                    width="280"
                  />
                </div>
                <p className="d-label-1 mb-12">Canada </p>
                <h2 className="d-heading-3 mb-4">
                  Financial Transactions and Reports Analysis Centre{" "}
                </h2>
                <p className="d-body-text-1">
                  Money Service Business Registration{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">M20280268 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Capital Inc. </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136333632352c227072696d617279536561726368223a7b226f72674e616d65223a226e65786f222c2273656172636854797065223a317d7d/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.fintrac-canafe.gc.ca{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="168"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/7db0f863f7-1631610934/logo-fintrac-canafe.png"
                    width="280"
                  />
                </div>
                <p className="d-label-1 mb-12">Canada </p>
                <h2 className="d-heading-3 mb-4">
                  Financial Transactions and Reports Analysis Centre of Canada{" "}
                </h2>
                <p className="d-body-text-1">
                  Money Service Business Registration{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">M21199887 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Financial Services Inc. </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136353837362c227072696d617279536561726368223a7b226f72674e616d65223a226e65786f222c2273656172636854797065223a317d7d/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.fintrac-canafe.gc.ca{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="92"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/b649e1a148-1631610934/logo-companies-registry.png"
                    width="143"
                  />
                </div>
                <p className="d-label-1 mb-12">Hong Kong </p>
                <h2 className="d-heading-3 mb-4">Companies Registry </h2>
                <p className="d-body-text-1">
                  Trust or Company Service Provider License{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">TC007556 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Finance Limited </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.tcsp.cr.gov.hk/tcspls/search/public-search?k=NEXO%20FINANCE%20LIMITED"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.cr.gov.hk{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="100"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/80f3b717aa-1667576271/logo_oam.png"
                    width="192"
                  />
                </div>
                <p className="d-label-1 mb-12">Italy </p>
                <h2 className="d-heading-3 mb-4">
                  Organismo Agenti e Mediatori (OAM){" "}
                </h2>
                <p className="d-body-text-1">Virtual Currency Operator </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">PSV78 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Services S.r.l. </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.organismo-am.it/elenchi-registri/operatori_valute_virtuali/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.organismo-am.it{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="94"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/eb878ac894-1631887530/logo-fntt.png"
                    width="85"
                  />
                </div>
                <p className="d-label-1 mb-12">Lithuania </p>
                <h2 className="d-heading-3 mb-4">
                  Financial Crime Investigation Service{" "}
                </h2>
                <p className="d-body-text-1">
                  Registration as Virtual Currency Exchange Operator and
                  Depository Virtual Currency Wallet Operator{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">N/A </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Services UAB </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="432"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/b511b67685-1670493875/poland-finance-ministry.png"
                    width="970"
                  />
                </div>
                <p className="d-label-1 mb-12">Poland </p>
                <h2 className="d-heading-3 mb-4">Ministry of Finance </h2>
                <p className="d-body-text-1">
                  Registration of Activities in the Field of Virtual Currencies{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">RDWW-533 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Services Sp. z o.o. </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.slaskie.kas.gov.pl/c/document_library/get_file?uuid=73ffde9b-0123-4594-8a94-a4d458218386&amp;groupId=3559133"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.slaskie.kas.gov.pl{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="293"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/e25b8f6d24-1656937716/fsanewlogo5.png"
                    width="400"
                  />
                </div>
                <p className="d-label-1 mb-12">Seychelles </p>
                <h2 className="d-heading-3 mb-4">
                  Financial Services Authority (FSA){" "}
                </h2>
                <p className="d-body-text-1">Securities Dealer License </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">SD121 </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt Markets Ltd </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://fsaseychelles.sc/search-business/nexo-markets-ltd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.fsaseychelles.sc{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between w-full sm:w-1/2 md:w-1/3 p-20 md:p-16 border-2 border-color-100">
              <div>
                <div className="min-h-64 mb-32">
                  <img
                    className="w-auto h-64 object-contain object-left transform-origin-left"
                    alt=""
                    height="128"
                    loading="lazy"
                    src="https://nexo.io/media/pages/licenses-and-registrations/d7ec433a06-1631610933/flag-swiss.png"
                    width="116"
                  />
                </div>
                <p className="d-label-1 mb-12">Switzerland </p>
                <h2 className="d-heading-3 mb-4">
                  Swiss Financial Market Supervisory Authority{" "}
                </h2>
                <p className="d-body-text-1">
                  Affiliated Member of the Self-Regulatory Organization “SO-FIT”
                  – Geneva{" "}
                </p>
              </div>
              <div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    REFERENCE NO.{" "}
                  </p>
                  <p className="d-body-text-2">N/A </p>
                </div>
                <div className="">
                  <p className="d-heading-6 mb-4 text-color-400 uppercase">
                    COMPANY{" "}
                  </p>
                  <p className="d-body-text-2">Paxcrypt AG </p>
                </div>
                <p className=" overflow-hidden text-ellipsis">
                  <a
                    className="d-body-text-2 b-anchor text-color-400"
                    href="https://www.finma.ch/en/authorisation/self-regulatory-organisations-sros/sro-member-search/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.finma.ch{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
