import React, { ReactNode, useEffect } from "react";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
const Layout = ({ children }) => (
  <>
    <Nav />
    <ScrollToTopOnMount/>
    <div className="pt-12">{children}</div>
    <Footer />
  </>
);

export default Layout;
