import * as React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { currencyFormatter } from "../../utils/coins";

export default function BalanceCard({
  title = "Total Balance",
  action,
  balance,
  onClick
}) {
  return (
    <Grid item xs={12} md={6} lg={5} >
      <div className="bg-blue-700 p-3  text-white h-[124px] flex flex-col rounded justify-between">
        <h2>{title.toLocaleUpperCase()}</h2>
        <Typography component="p" variant="h4" className="">
          {currencyFormatter.format(balance)}
        </Typography>

        {/* <div> */}
          {action && <p onClick={onClick} className="cursor-pointer bg-white text-blue-500 text-xs  text-center rounded-3xl w-max px-4 py-1" href="#">
            {action}
          </p>}
        {/* </div> */}
      </div>
    </Grid>
  );
}
