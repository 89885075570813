import Layout from "../../components/Layout";
import { NavLink } from "react-router-dom";
import OTCFAQ from "../../components/FAQ/otc";

export default function OTC() {
  return (
    <Layout>
      <main>
        <div className="bg-blue-900 py-10 px-8 md:px-16 lg:px-28 flex flex-col md:flex-row items-center justify-between">
          <div className="text-white">
            <h3 className="font-bold mb-8">OTC</h3>
            <h2 className="text-5xl font-bold">Paxcrypt OTC Desk</h2>
            <p className="text-3xl lg:w-[450px] font-light mb-9">
              Large crypto trades with private and personalized services
            </p>
            <NavLink
              to="/login"
              className="px-6 py-3 rounded-3xl bg-blue-600 hover:bg-blue-800 font-semibold"
            >
              Trade OTC with Paxcrypt
            </NavLink>
          </div>
          <img
            className="w-10/12 md:w-5/12"
            src={`otc-exchange-platform.png`}
            alt="logo"
          />
        </div>

        <div className="py-10 px-8 md:px-16 lg:px-28 ">
          <div className="text-black">
            <h3 className="font-medium text-4xl mb-12">
              What is the Paxcrypt OTC Desk?
            </h3>
            <p className="text-lg lg:w-[650px] font-light mb-9">
              Paxcrypt's over-the-counter (OTC) desk offers a premium service
              that allows traders to execute orders off the open Paxcrypt
              exchange. We offer deeper liquidity for tighter spreads as well as
              a more private, personalized service for institutional clients and
              high net-worth individuals needing to fill large orders. Paxcrypt'
              OTC desk provides execution and settlement services that are
              discreet, secure and ultra-competitive.{" "}
            </p>
          </div>
        </div>
        <div className="bg-blue-900 py-10 px-8 md:px-16 lg:px-28 flex items-center justify-between">
          <div className="text-white pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
            <h3 className="text-4xl text-white font-semibold text-center my-10">
              Paxcrypt OTC features
            </h3>
            <div className="mt-0 mr-auto mb-0 ml-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
              <div className="grid mb-8 gap-8 row-gap-5 lg:grid-cols-3">
                <div
                  className="shadow-sm overflow-hidden relative p-px transition duration-300 transform  rounded
          hover:scale-105 group hover:shadow-x"
                >
                  <div className=" rounded-sm pt-5 pr-5 pb-5 pl-5 relative">
                    <div className="flex flex-col mb-2 ">
                      <img
                        className="w-7 mr-3 mb-4"
                        src={`dollars.svg`}
                        alt="logo"
                      />

                      <p className="font-semibold text-2xl leading-5 mb-3">
                        Worry-free liquidity
                      </p>
                    </div>
                    <p className="text-lg font-extralight mb-2">
                      Get a single quote for $10,000-$1M orders (and higher on
                      a case-by-case basis), knowing you’ll get your entire lot
                      and its exact price, before you trade.
                    </p>
                  </div>
                </div>
                <div
                  className="shadow-sm overflow-hidden relative p-px transition duration-300 transform  rounded
          hover:scale-105 group hover:shadow-x"
                >
                  <div className=" rounded-sm pt-5 pr-5 pb-5 pl-5 relative">
                    <div className="flex flex-col mb-2 ">
                      <img
                        className="w-7 mr-3 mb-4"
                        src={`star.svg`}
                        alt="logo"
                      />

                      <p className="font-semibold text-2xl leading-5 mb-3">
                        World class service
                      </p>
                    </div>
                    <p className="text-lg font-extralight mb-2">
                      We build relationships that last. Our professional,
                      experienced team is available to assist you anytime via
                      email, chat or over the phone.
                    </p>
                  </div>
                </div>
                <div
                  className="shadow-sm overflow-hidden relative p-px transition duration-300 transform  rounded
          hover:scale-105 group hover:shadow-x"
                >
                  <div className=" rounded-sm pt-5 pr-5 pb-5 pl-5 relative">
                    <div className="flex flex-col mb-2 ">
                      <img
                        className="w-7 mr-3 mb-4"
                        src={`rocket.svg`}
                        alt="logo"
                      />

                      <p className="font-semibold text-2xl leading-5 mb-3">
                        Automated RFQ
                      </p>
                    </div>
                    <p className="text-lg font-extralight mb-2">
                      Request live trade quotes, execute orders and settle
                      instantly using our request-for-quote (RFQ) feature in the
                      OTC Portal.(Coming soon)
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid mb-8 gap-8 row-gap-5 lg:grid-cols-3">
                <div
                  className="shadow-sm overflow-hidden relative p-px transition duration-300 transform  rounded
          hover:scale-105 group hover:shadow-x"
                >
                  <div className=" rounded-sm pt-5 pr-5 pb-5 pl-5 relative">
                    <div className="flex flex-col mb-2 ">
                      <img
                        className="w-7 mr-3 mb-4"
                        src={`gear.svg`}
                        alt="logo"
                      />

                      <p className="font-semibold text-2xl leading-5 mb-3">
                        1-1 service
                      </p>
                    </div>
                    <p className="text-lg font-extralight mb-2">
                      Dedicated service at every step. We work to exceed your
                      expectations and build a long-lasting relationship.
                    </p>
                  </div>
                </div>
                <div
                  className="shadow-sm overflow-hidden relative p-px transition duration-300 transform  rounded
          hover:scale-105 group hover:shadow-x"
                >
                  <div className=" rounded-sm pt-5 pr-5 pb-5 pl-5 relative">
                    <div className="flex flex-col mb-2 ">
                      <img
                        className="w-7 mr-3 mb-4"
                        src={`globe.svg`}
                        alt="logo"
                      />

                      <p className="font-semibold text-2xl leading-5 mb-3">
                        Global 24/7/365 access
                      </p>
                    </div>
                    <p className="text-lg font-extralight mb-2">
                      Our team of experienced traders, strategically located
                      around the globe, are available around-the-clock, seven
                      days per week.
                    </p>
                  </div>
                </div>
                <div
                  className="shadow-sm overflow-hidden relative p-px transition duration-300 transform  rounded
          hover:scale-105 group hover:shadow-x"
                >
                  <div className=" rounded-sm pt-5 pr-5 pb-5 pl-5 relative">
                    <div className="flex flex-col mb-2 ">
                      <img
                        className="w-7 mr-3 mb-4"
                        src={`thumbup.svg`}
                        alt="logo"
                      />

                      <p className="font-semibold text-2xl leading-5 mb-3">
                        Settlement flexiblity
                      </p>
                    </div>
                    <p className="text-lg font-extralight mb-2">
                      Our settlement options allow you to settle trades within
                      24 hours using your Kraken account, your bank and/or the
                      external wallet of your choice.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <NavLink
                  to="/login"
                  className="px-6 py-3 rounded-3xl bg-blue-600 hover:bg-blue-800 font-semibold"
                >
                  Trade OTC with Paxcrypt
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-indigo-100 py-10 px-8  md:px-16 lg:px-28 flex items-center justify-between">
          <div className="mx-auto pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
            <h3 className="text-4xl text-indigo-800 font-semibold text-center my-10">
              How OTC works
            </h3>
            <div className=" mt-0 mr-auto mb-0 ml-auto lg:max-w-screen-xl sm:max-w-xl md:max-w-full">
              <div className="grid mt-0 mr-auto mb-0 ml-auto">
                <div className="flex">
                  <div className="flex flex-col items-center mt-0 mr-6 mb-0 ml-0">
                    <div className="w-px h-10 opacity-0 sm:h-full"></div>
                    <div>
                      <div className="flex bg-indigo-500 text-white justify-center items-center w-8 h-8 text-xs font-medium border rounded-full">
                        <p>1</p>
                      </div>
                    </div>
                    <div className="w-px h-[100px] bg-gray-800"></div>
                  </div>
                  <div className="flex flex-col pt-0 pr-0 pb-6 pl-0 sm:items-center sm:flex-row sm:pb-0">
                    <div>
                      <p className="font-semibold text-3xl sm:text-base">
                        Sign up for OTC trading
                      </p>
                      <p className="text-lg text-gray-700 lg:max-w-[400px]">
                        Contact us and complete the onboarding process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col items-center mt-0 mr-6 mb-0 ml-0">
                    <div className="w-px h-10 opacity-0"></div>
                    <div>
                      <div className="flex bg-indigo-500 text-white justify-center items-center w-8 h-8 text-xs font-medium border rounded-full">
                        <p>2</p>
                      </div>
                    </div>
                    <div className="w-px h-[100px] bg-gray-800"></div>
                  </div>
                  <div className="flex flex-col pt-0 pr-0 pb-6 pl-0 sm:items-center sm:flex-row sm:pb-0">
                    <div>
                      <p className="font-semibold text-3xl sm:text-base">
                        Trade over chat
                      </p>
                      <p className="text-lg text-gray-700 lg:max-w-[400px]">
                        Chat securely with our trade desk to confirm the asset,
                        lot size and price of your trade. Then, fund your
                        account with wire instructions that are provided to you.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-col items-center mt-0 mr-6 mb-0 ml-0">
                    <div className="w-px h-10 opacity-0 "></div>
                    <div>
                      <div className="flex bg-indigo-500 text-white justify-center items-center w-8 h-8 text-xs font-medium border rounded-full">
                        <p>3</p>
                      </div>
                    </div>
                    <div className="w-px h-full bg-gray-300 opacity-0"></div>
                  </div>
                  <div className="flex flex-col pt-0 pr-0 pb-6 pl-0 sm:items-center sm:flex-row sm:pb-0">
                    <div>
                      <p className="font-semibold text-3xl sm:text-base">
                        Complete trade
                      </p>
                      <p className="text-lg text-gray-700 lg:max-w-[400px]">
                        Once we receive your funds, our trader will send the
                        asset to your paxcrypt account..
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex mt-8 items-center justify-center">
              <NavLink
                to="/login"
                className="px-6 py-3 text-white rounded-3xl bg-blue-600 hover:bg-blue-800 font-semibold"
              >
                Trade OTC with Paxcrypt
              </NavLink>
            </div>
          </div>
        </div>
        <div className="bg-blue-900 py-10 px-6 md:px-16 lg:px-28 flex items-center justify-between">
          <div className="mx-auto pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
            <h3 className="text-4xl text-white font-semibold text-center my-10">
              Contact OTC
            </h3>
            <div className="bg-white lg:max-w-[450px] rounded-xl p-8">
              <h3 className="font-bold text-2xl pb-4 border-b-4 border-b-indigo-200 mb-4">
                Over-the-counter (OTC) trading
              </h3>
              <p className="text-light">
                Thanks for your interest in using our OTC desk services!
              </p>
              <p className="font-bold my-2">
                To get a quote for your Over-the-Counter (OTC) trade, please
                provide details in the chatbox in the bottom right corner or
                your screen:
              </p>
              <ul class="ml-4 list-disc ">
                <li class="text-indigo-500">
                  <span class="text-black">Base Currency</span>
                </li>
                <li class="text-indigo-500">
                  <span class="text-black">Quote Currenct</span>
                </li>
                <li class="text-indigo-500">
                  <span class="text-black">Amount</span>
                </li>
              </ul>
              <p className="text-indigo-400 my-3">
                e.g. I'd like to buy $500k worth of USDC.
              </p>
              <p className="text-gray-400 font-bold">
                Our support team will respond shortly.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-10 px-6 md:px-16 lg:px-28 flex items-center justify-between">
          <div className="mx-auto pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
            <OTCFAQ />
    
          </div>
        </div>
      </main>
    </Layout>
  );
}
