import React, { useState, useEffect } from "react";
// import Link from "next/link";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { getPrices, getTrendingCoins } from "../../api/balance";
import {
  Backdrop,
  CircularProgress,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from "@mui/material";
import { useAuth } from "../../context/auth";
import LoadingScreen from "../LoadingScreen";
import BalanceCard from "../BalanceCard/BalanceCard";
import { coins, coinsAbv, currencyFormatter } from "../../utils/coins";
import { useDashboardSection } from "../../context/dashSection";
import EastIcon from "@mui/icons-material/East";

export default function Balance() {
  // eslint-disable-next-line
  const { currentSection, setCurrentSection } = useDashboardSection();
  // const [wallets, setWallets] = useState(null);
  const [prices, setPrices] = useState([]);
  const [trendingCoins, setTrendingCoins] = useState([]);
  const [balanceLoading, setBalanceLoading] = useState(true);
  const { user, loading } = useAuth();

  const columns = [
    { id: "name", label: "Currency", minWidth: 170 },
    { id: "price", label: "Price", minWidth: 100, align: "right" },
    {
      id: "balance",
      label: "Balance",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //   id: "balance(USD)",
    //   label: "Balance (USD)",
    //   minWidth: 170,
    //   align: "right",
    //   format: (value) => value.toLocaleString("en-US"),
    // },
  ];
  useEffect(() => {
    async function loadPrices() {
      const _prices = await getPrices();
      const _trendingCoins = await getTrendingCoins();
      setPrices(_prices.data);
      setTrendingCoins(_trendingCoins.data.coins);
      setBalanceLoading(false);
    }
    loadPrices();
  }, [user]);

  if (loading || balanceLoading)
    return <LoadingScreen loading={loading || balanceLoading} />;
  return (
    <Box className="flex flex-col lg:flex-row gap-3">
      <div className="lg:w-8/12">
        <Grid container spacing={3}>
          <BalanceCard
            action="deposit"
            balance={user.balances.total.toFixed(2)}
            onClick={() => setCurrentSection("receive")}
          />
          <BalanceCard
            action="stake"
            title="Total Stakes"
            balance={user.balances.total_stakes.toFixed(2)}
            onClick={() => setCurrentSection("staking")}
          />
        </Grid>
        <Grid item xs={12} className="my-12   md:max-w-[100%] ">
          <Paper
            className="bg-[#00234E] rounded-lg"
            sx={{ display: "flex", flexDirection: "column" }}
            mx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            {balanceLoading ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <TableContainer className="bg-[#00234E] ">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="bg-[#00234E] text-white">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          className="bg-[#00234E] text-white"
                          key={column.id}
                          align={column.align}
                          // style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {coins.map((coin) => {
                      return (
                        <TableRow
                          className="text-white "
                          key={coin._name}
                          hover
                          tabIndex={-1}
                        >
                          <TableCell
                            className="text-white border-b-[#ffffff29]"
                            align="left"
                          >
                            <div className="flex ">
                              <img
                                className=" w-7 h-7 md:w-10 md:h-10 mr-3 inline-flex"
                                src={`/${coin.id}-logo.png`}
                                alt="logo"
                              />
                              <div className="flex flex-col">
                                <span>{coin._name.toUpperCase()}</span>

                                <span className="text-gray-500">
                                  {coinsAbv[coin._name].toUpperCase()}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className="text-white border-b-[#ffffff29] text-xs md:text-lg"
                            align="right"
                          >

                            {coin._name === "usdc"
                              ? "1"
                              : currencyFormatter.format(
                                  prices[coin?._name]?.usd
                                )}
                          </TableCell>
                          <TableCell
                            className="text-white border-b-[#ffffff29] text-xs md:text-lg"
                            align="right"
                          >
                            {user.balances[coin.id].toFixed(3)}{" "}
                            {coin.id.toUpperCase()} <br />
                            {currencyFormatter.format(
                              user.balances[`${coin.id}_dollar`].toFixed(2)
                            )}
                          </TableCell>
                          {/* <TableCell
                            className="text-white border-b-[#ffffff29] text-xs md:text-lg"
                            align="right"
                          >
                            {currencyFormatter.format(
                              user.balances[`${coin.id}_dollar`].toFixed(2)
                            )}
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </div>
      <div className="lg:w-4/12 flex flex-col mb-40">
        <div className="w-full border-[1px] mb-4 p-4 border-gray-600 rounded-md flex">
          <div>
            <p className="text-white text-lg font-semibold mb-2 ">
              Earn Bitcoin
            </p>
            <p className="text-gray-500 ">
              Invite a friend and you could both get crypto
            </p>
            <div className="flex mt-2">
              <NavLink to="/referral" className="text-blue-500 mr-2">
                Learn More
              </NavLink>{" "}
              <EastIcon className="text-blue-500" />
            </div>
          </div>
          <img className="w-24 mr-2 h-24" src={`/box.png`} alt="gift" />
        </div>
        <div className="w-full border-[1px] p-3 px-1 border-gray-600 rounded-md">
          <p className="text-white text-xl font-semibold my-4 ml-4">
            Top Movers
          </p>
          {trendingCoins.map((coin) => (
            <div
              className="flex items-start my-3 w-full cursor-pointer hover:bg-gray-900 rounded-lg p-4 py-3"
              key={coin.item.name}
            >
              <img
                className="w-7 h-7 rounded-full mr-4 self-center"
                src={coin.item.large}
                alt="coin"
              />
              <div className="flex flex-col">
                <span className="text-white">{coin.item.name}</span>
                <span className="text-gray-400">{coin.item.symbol}</span>
              </div>
              <span className="text-base text-white justify-self-end ml-auto">
                ${(coin.item.price_btc * prices.bitcoin.usd).toFixed(2)}
              </span>{" "}
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
}
