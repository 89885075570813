import { get, post, put, destroy } from "./base";
import { Cookies } from "react-cookie";
import axios from "axios";

const apiClient = axios.create({
  baseURL:
    "https://api.coingecko.com/api/v3/simple/price?ids=btc&vs_currencies=usd", // <- ENV variable
});

// set up cookies
const cookies = new Cookies();

export const getWallets = async (token) => {
  return await get("/wallet/", {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getPrice = async (token) => {
  return await get("/wallet/price", {
    headers: {
      // Authorization: `Token ${token}`,
      // XCSRFToken:
      //   "tIgHUrmgLoRXFOws6WmAG6M1KPSidR8TeP1cy8xiQ1RjXUsWfWgERIRp5ZB8SQt9",
    },
  });
};

export const getTransactions = async (token) => {
  return await get("/wallet/alltransactions", {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getStakes = async (token) => {
  return await get("/wallet/stake", {
    headers: { Authorization: `Token ${token}` },
  });
};

export const getAddresses = async (token) => {
  return await get("/wallet/address", {
    headers: { Authorization: `Token ${token}` },
  });
};

export const createStake = async (token, body) => {
  return await post("/wallet/stake/", body, {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const getPrices = async () => {
  return await apiClient.get(
    "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Clitecoin%2Csolana%2Cusdc%2Cripple&vs_currencies=usd"
  );
};

export const getTrendingCoins = async () => {
  return await apiClient.get(
    "https://api.coingecko.com/api/v3/search/trending"
  );
};

export const getTrendingCoins2 = async () => {
  return await apiClient.get(
    "https://pro-api.coinmarketcap.com/v1/cryptocurrency/trending/latest?CMC_PRO_API_KEY=a4bc251c-3db6-443d-8376-88d58067e83a"
  );
};

export const withdraw = async (token, body) => {
  return await post("/wallet/withdraw", body, {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const deposit = async (token, body) => {
  return await post("/wallet/receive", body, {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const swapCoins = async (token, body) => {
  return await post("/wallet/swap", body, {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const updatePrices = async () => {
  return await post("/wallet/price", {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};
