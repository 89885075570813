import Layout from "../../components/Layout";
import { NavLink } from 'react-router-dom';


export default function About() {
  return (
    <Layout>
      <main>
        <div>
          <div className="">
            <div
              className="bg-blue-500 mt-0 mr-auto mb-0 ml-auto pt-16  pr-4 pb-16 pl-4 sm:max-w-xl md:max-w-full lg:max-w-screen-xl
        md:px-24 lg:px-8 lg:py-20"
            >
              <div className="shadow-xl pt-8 pr-8 pb-8 pl-8 sm:p-16 bg-white">
                <div className="flex flex-col items-center lg:flex-row">
                  <div className="lg:mb-0 lg:w-1/2 lg:pr-5 mb-6">
                    <div>
                      <p className="block text-3xl font-bold  text-gray-900 leading-7 font-sans sm:text-4xl">
                        <span>
                          Paxcrypt is the world&apos;s leading and most trusted
                          storage institution in the digital finance industry
                        </span>
                      </p>
                      <p
                        className="inline text-3xl font-bold tracking-tight text-gray-900 font-sans sm:text-4xl
                  sm:leading-none"
                      ></p>
                    </div>
                  </div>
                  <div className="lg:w-1/2">
                    <p className="mb-4 text-base text-left text-gray-700"></p>
                    <p>
                      Paxcrypt offers a secure and user-friendly platform for
                      managing cryptocurrency investments. Our mission is to
                      empower people to take control of their financial future
                      and manage their digital assets with ease.
                    </p>

                    {/* <a
                      className="mt-4 text-blue-700 text-center flex font-semibold items-center transition-colors duration-200
                hover:text-blue-900"
                    >
                      Learn more
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-white pt-16 pr-4 pb-16 pl-4 mt-0 mr-auto mb-0 ml-auto lg:max-w-screen-xl sm:max-w-xl md:max-w-full
        md:px-24 lg:px-8 lg:py-20"
            >
              <div className="grid grid-cols-2 row-gap-8 md:grid-cols-4">
                <div className="text-center md:border-r">
                  <p className="text-center text-4xl mb-2 font-bold lg:text-5xl xl:text-6xl text-blue-700">
                    1M+
                  </p>
                  <p className="text-center text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                    Users
                  </p>
                </div>
                <div className="text-center md:border-r">
                  <p className="text-center text-4xl mb-2 font-bold text-blue-700 lg:text-5xl xl:text-6xl">
                    $53B
                  </p>
                  <p
                    className="text-center text-sm font-medium tracking-widest text-gray-800 uppercase
              lg:text-base"
                  >
                    Transacted
                  </p>
                </div>
                <div className="text-center md:border-r">
                  <p className="text-center text-4xl mb-2 font-bold text-blue-700 lg:text-5xl xl:text-6xl">
                    $2B
                  </p>
                  <p className="text-center text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                    Assets on Platform
                  </p>
                </div>
                <div className="text-center md:border-r">
                  <p className="text-center text-4xl mb-2 font-bold text-blue-700 lg:text-5xl xl:text-6xl">
                    20+
                  </p>
                  <p className="text-center text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                    Countries
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="relative py-12 bg-gray-900 sm:py-16 lg:py-20">
                <div className="absolute inset-0">
                  <img
                    src="https://images.unsplash.com/photo-1621504450181-5d356f61d307?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
                    className="object-cover btn- w-full h-full"
                    alt="banner"
                  />
                </div>
                <div className="relative max-w-7xl px-4 mx-auto sm:px-6 lg:px-8">
                  <div className="flex items-center justify-start mx-auto max-w-6xl">
                    <div className="w-full text-center lg:text-left lg:w-1/2 rounded-xl backdrop-blur-lg bg-white/90">
                      <div className="px-10 py-12 lg:px-12 lg:py-14">
                        <p className="mt-5 text-3xl font-bold text-gray-900 sm:text-4xl lg:text-5xl">
                          The future of money
                          <br />
                          is here
                        </p>
                        <p className="mt-5 text-base font-normal text-gray-600">
                          Over 1 million people and businesses trust us to buy,
                          sell, and manage crypto.
                        </p>
                        <div className="mt-9">
                          <NavLink
                            to="/sign-up"
                            className="px-8 py-3 text-base font-bold text-white bg-gray-900 leading-7 transition-all
                      duration-200 border border-transparent rounded hover:bg-gray-700 focus:outline-none focus:ring-2
                      focus:ring-offset-2 focus:ring-gray-900 inline-flex items-center justify-center"
                          >
                            Get Started
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
              <div className="sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-0 mr-auto mb-0 ml-auto w-full">
                <div className="mt-0 mr-auto mb-10 ml-auto max-w-xl md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                  <div
                    className="mt-0 mr-auto mb-6 ml-auto text-3xl font-bold leading-none tracking-tight text-gray-900 max-w-lg
              font-sans sm:text-4xl md:mx-auto"
                  >
                    <div className="inline-block relative">
                      <p className="inline">
                        <svg
                          viewBox="0 0 52 24"
                          fill="currentColor"
                          className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20
                    text-gray-300 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                        >
                          <defs>
                            <pattern x="0" y="0" width=".135" height=".30">
                              <circle cx="1" cy="1" r=".7" />
                            </pattern>
                          </defs>
                          <rect
                            fill="url(#34f481be-159a-4846-821d-9ca19fb6bcc5)"
                            width="52"
                            height="24"
                          />
                        </svg>
                      </p>
                      <p
                        className="font-bold text-3xl inline tracking-tight relative font-sans sm:text-4xl
                  sm:leading-none"
                      >
                        Mission, Vision & Values
                      </p>
                    </div>
                  </div>
                  <p className="text-gray-700 text-base md:text-lg">
                    A service made with our clients in mind.
                  </p>
                </div>
                <div className="mt-0 mr-0 mb-10 ml-0 grid gap-8 row-gap-8 lg:grid-cols-3">
                  <div className="sm:text-center">
                    <p
                      className="text-blue-600 mt-0 mr-auto mb-4 ml-auto flex w-16 h-16 bg-blue-50 items-center justify-center
                rounded-full sm:w-24 sm:h-24"
                    >
                      <svg
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        className="w-7"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m511.4 38.222c-1.109-20.338-17.284-36.511-37.622-37.621-41.038-2.242-121.342-.061-198.13
                  39.656-39.145 20.248-80.545 54.577-113.584 94.185-.407.488-.803.979-1.207 1.468l-74.98
                  5.792c-12.342.954-23.335 7.423-30.161 17.747l-51.154 77.372c-5.177 7.83-6 17.629-2.203 26.212 3.798
                  8.584 11.602 14.566 20.877 16.003l63.171 9.784c-.223 1.228-.447 2.455-.652 3.683-2.103 12.58 2.065
                  25.514 11.151 34.599l87.992 87.993c7.533 7.533 17.712 11.686 28.142 11.686 2.148 0 4.308-.177
                  6.458-.536 1.228-.205 2.455-.429 3.683-.652l9.784 63.172c1.437 9.275 7.419 17.08 16.001 20.877 3.571
                  1.58 7.35 2.36 11.112 2.36 5.283-.001 10.529-1.539 15.101-4.562l77.372-51.155c10.325-6.827
                  16.793-17.82 17.745-30.161l5.792-74.979c.489-.404.981-.8 1.469-1.207 39.609-33.039 73.939-74.439
                  94.186-113.585 39.719-76.791 41.896-157.096 39.657-198.131zm-175.394 393.037-74.011
                  48.933-9.536-61.565c31.28-9.197 62.223-23.927 91.702-43.66l-3.773 48.845c-.235 3.047-1.833 5.762-4.382
                  7.447zm-129.895-37.377-87.993-87.993c-2.245-2.246-3.283-5.401-2.774-8.44 2.616-15.643 6.681-30.534
                  11.713-44.562l132.028 132.028c-16.848 6.035-31.939 9.635-44.534
                  11.741-3.044.506-6.195-.529-8.44-2.774zm-117.923-222.269 48.844-3.773c-19.734 29.479-34.464
                  60.422-43.661 91.702l-61.564-9.535 48.934-74.012c1.686-2.55 4.401-4.147 7.447-4.382zm270.155
                  155.286c-24.233 20.213-47.756 34.833-69.438 45.412l-149.221-149.221c13.858-28.304 30.771-51.873
                  45.417-69.431 30.575-36.655 68.602-68.276 104.331-86.756 70.474-36.453 144.725-38.416 182.713-36.348
                  5.028.274 9.027 4.273 9.301 9.302 2.071 37.988.104 112.238-36.349 182.713-18.479 35.728-50.1
                  73.754-86.754 104.329z"
                        />
                        <path
                          d="m350.721 236.243c19.202-.002 38.412-7.312 53.031-21.931 14.166-14.165
                  21.966-32.999 21.966-53.031s-7.801-38.866-21.966-53.031c-29.242-29.243-76.822-29.241-106.062 0-14.166
                  14.165-21.967 32.999-21.967 53.031s7.802 38.866 21.967 53.031c14.622 14.622 33.822 21.933 53.031
                  21.931zm-31.82-106.781c8.772-8.773 20.295-13.159 31.818-13.159 11.524 0 23.047 4.386 31.819 13.159
                  8.499 8.499 13.179 19.799 13.179 31.818s-4.68 23.32-13.179 31.819c-17.544 17.545-46.093 17.544-63.638
                  0-8.499-8.499-13.18-19.799-13.18-31.818s4.682-23.32 13.181-31.819z"
                        />
                        <path
                          d="m15.301 421.938c3.839 0
                  7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355
                  0-21.213-5.857-5.858-15.355-5.858-21.213 0l-48.972 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.928
                  2.929 6.767 4.394 10.606 4.394z"
                        />
                        <path
                          d="m119.761 392.239c-5.857-5.858-15.355-5.858-21.213 0l-94.154
                  94.155c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.767 4.393 10.606 4.393s7.678-1.464
                  10.606-4.394l94.154-94.154c5.859-5.858 5.859-15.355.001-21.213z"
                        />
                        <path
                          d="m143.429 437.12-48.973
                  48.973c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464
                  10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.212
                  0z"
                        />
                      </svg>
                    </p>
                    <p className="leading-5 font-bold mb-2">Misson</p>
                    <p className="text-sm text-gray-900 max-w-md sm:mx-auto mb-3">
                      Paxcrypt is committed to successfully solving
                      inefficiencies in the lending markets by creating
                      innovative, convenient and sustainable solutions. By
                      harnessing the potential behind blockchain technology,
                      Paxcrypt is pioneering a new digital financial system.
                    </p>
                  </div>
                  <div className="sm:text-center">
                    <p
                      className="text-blue-600 mt-0 mr-auto mb-4 ml-auto flex w-16 h-16 bg-blue-50 items-center justify-center
                rounded-full sm:w-24 sm:h-24"
                    >
                      <svg
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        className="w-7"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m511.4 38.222c-1.109-20.338-17.284-36.511-37.622-37.621-41.038-2.242-121.342-.061-198.13
                  39.656-39.145 20.248-80.545 54.577-113.584 94.185-.407.488-.803.979-1.207 1.468l-74.98
                  5.792c-12.342.954-23.335 7.423-30.161 17.747l-51.154 77.372c-5.177 7.83-6 17.629-2.203 26.212 3.798
                  8.584 11.602 14.566 20.877 16.003l63.171 9.784c-.223 1.228-.447 2.455-.652 3.683-2.103 12.58 2.065
                  25.514 11.151 34.599l87.992 87.993c7.533 7.533 17.712 11.686 28.142 11.686 2.148 0 4.308-.177
                  6.458-.536 1.228-.205 2.455-.429 3.683-.652l9.784 63.172c1.437 9.275 7.419 17.08 16.001 20.877 3.571
                  1.58 7.35 2.36 11.112 2.36 5.283-.001 10.529-1.539 15.101-4.562l77.372-51.155c10.325-6.827
                  16.793-17.82 17.745-30.161l5.792-74.979c.489-.404.981-.8 1.469-1.207 39.609-33.039 73.939-74.439
                  94.186-113.585 39.719-76.791 41.896-157.096 39.657-198.131zm-175.394 393.037-74.011
                  48.933-9.536-61.565c31.28-9.197 62.223-23.927 91.702-43.66l-3.773 48.845c-.235 3.047-1.833 5.762-4.382
                  7.447zm-129.895-37.377-87.993-87.993c-2.245-2.246-3.283-5.401-2.774-8.44 2.616-15.643 6.681-30.534
                  11.713-44.562l132.028 132.028c-16.848 6.035-31.939 9.635-44.534
                  11.741-3.044.506-6.195-.529-8.44-2.774zm-117.923-222.269 48.844-3.773c-19.734 29.479-34.464
                  60.422-43.661 91.702l-61.564-9.535 48.934-74.012c1.686-2.55 4.401-4.147 7.447-4.382zm270.155
                  155.286c-24.233 20.213-47.756 34.833-69.438 45.412l-149.221-149.221c13.858-28.304 30.771-51.873
                  45.417-69.431 30.575-36.655 68.602-68.276 104.331-86.756 70.474-36.453 144.725-38.416 182.713-36.348
                  5.028.274 9.027 4.273 9.301 9.302 2.071 37.988.104 112.238-36.349 182.713-18.479 35.728-50.1
                  73.754-86.754 104.329z"
                        />
                        <path
                          d="m350.721 236.243c19.202-.002 38.412-7.312 53.031-21.931 14.166-14.165
                  21.966-32.999 21.966-53.031s-7.801-38.866-21.966-53.031c-29.242-29.243-76.822-29.241-106.062 0-14.166
                  14.165-21.967 32.999-21.967 53.031s7.802 38.866 21.967 53.031c14.622 14.622 33.822 21.933 53.031
                  21.931zm-31.82-106.781c8.772-8.773 20.295-13.159 31.818-13.159 11.524 0 23.047 4.386 31.819 13.159
                  8.499 8.499 13.179 19.799 13.179 31.818s-4.68 23.32-13.179 31.819c-17.544 17.545-46.093 17.544-63.638
                  0-8.499-8.499-13.18-19.799-13.18-31.818s4.682-23.32 13.181-31.819z"
                        />
                        <path
                          d="m15.301 421.938c3.839 0
                  7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355
                  0-21.213-5.857-5.858-15.355-5.858-21.213 0l-48.972 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.928
                  2.929 6.767 4.394 10.606 4.394z"
                        />
                        <path
                          d="m119.761 392.239c-5.857-5.858-15.355-5.858-21.213 0l-94.154
                  94.155c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.767 4.393 10.606 4.393s7.678-1.464
                  10.606-4.394l94.154-94.154c5.859-5.858 5.859-15.355.001-21.213z"
                        />
                        <path
                          d="m143.429 437.12-48.973
                  48.973c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464
                  10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.212
                  0z"
                        />
                      </svg>
                    </p>
                    <p className="leading-5 font-bold mb-2">Vision</p>
                    <p className="text-sm text-gray-900 max-w-md sm:mx-auto mb-3">
                      We see a future for the financial world where all assets
                      are tokenized on-chain and people have efficient ways to
                      transfer or manage their funds. At Paxcrypt, we always
                      look ahead which is why we’ve designed our products to be
                      seamless, secure and instant -- the financial services of
                      tomorrow.
                    </p>
                  </div>
                  <div className="sm:text-center">
                    <p
                      className="text-blue-600 mt-0 mr-auto mb-4 ml-auto flex w-16 h-16 bg-blue-50 items-center justify-center
                rounded-full sm:w-24 sm:h-24"
                    >
                      <svg
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        className="w-7"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m511.4 38.222c-1.109-20.338-17.284-36.511-37.622-37.621-41.038-2.242-121.342-.061-198.13
                  39.656-39.145 20.248-80.545 54.577-113.584 94.185-.407.488-.803.979-1.207 1.468l-74.98
                  5.792c-12.342.954-23.335 7.423-30.161 17.747l-51.154 77.372c-5.177 7.83-6 17.629-2.203 26.212 3.798
                  8.584 11.602 14.566 20.877 16.003l63.171 9.784c-.223 1.228-.447 2.455-.652 3.683-2.103 12.58 2.065
                  25.514 11.151 34.599l87.992 87.993c7.533 7.533 17.712 11.686 28.142 11.686 2.148 0 4.308-.177
                  6.458-.536 1.228-.205 2.455-.429 3.683-.652l9.784 63.172c1.437 9.275 7.419 17.08 16.001 20.877 3.571
                  1.58 7.35 2.36 11.112 2.36 5.283-.001 10.529-1.539 15.101-4.562l77.372-51.155c10.325-6.827
                  16.793-17.82 17.745-30.161l5.792-74.979c.489-.404.981-.8 1.469-1.207 39.609-33.039 73.939-74.439
                  94.186-113.585 39.719-76.791 41.896-157.096 39.657-198.131zm-175.394 393.037-74.011
                  48.933-9.536-61.565c31.28-9.197 62.223-23.927 91.702-43.66l-3.773 48.845c-.235 3.047-1.833 5.762-4.382
                  7.447zm-129.895-37.377-87.993-87.993c-2.245-2.246-3.283-5.401-2.774-8.44 2.616-15.643 6.681-30.534
                  11.713-44.562l132.028 132.028c-16.848 6.035-31.939 9.635-44.534
                  11.741-3.044.506-6.195-.529-8.44-2.774zm-117.923-222.269 48.844-3.773c-19.734 29.479-34.464
                  60.422-43.661 91.702l-61.564-9.535 48.934-74.012c1.686-2.55 4.401-4.147 7.447-4.382zm270.155
                  155.286c-24.233 20.213-47.756 34.833-69.438 45.412l-149.221-149.221c13.858-28.304 30.771-51.873
                  45.417-69.431 30.575-36.655 68.602-68.276 104.331-86.756 70.474-36.453 144.725-38.416 182.713-36.348
                  5.028.274 9.027 4.273 9.301 9.302 2.071 37.988.104 112.238-36.349 182.713-18.479 35.728-50.1
                  73.754-86.754 104.329z"
                        />
                        <path
                          d="m350.721 236.243c19.202-.002 38.412-7.312 53.031-21.931 14.166-14.165
                  21.966-32.999 21.966-53.031s-7.801-38.866-21.966-53.031c-29.242-29.243-76.822-29.241-106.062 0-14.166
                  14.165-21.967 32.999-21.967 53.031s7.802 38.866 21.967 53.031c14.622 14.622 33.822 21.933 53.031
                  21.931zm-31.82-106.781c8.772-8.773 20.295-13.159 31.818-13.159 11.524 0 23.047 4.386 31.819 13.159
                  8.499 8.499 13.179 19.799 13.179 31.818s-4.68 23.32-13.179 31.819c-17.544 17.545-46.093 17.544-63.638
                  0-8.499-8.499-13.18-19.799-13.18-31.818s4.682-23.32 13.181-31.819z"
                        />
                        <path
                          d="m15.301 421.938c3.839 0
                  7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355
                  0-21.213-5.857-5.858-15.355-5.858-21.213 0l-48.972 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.928
                  2.929 6.767 4.394 10.606 4.394z"
                        />
                        <path
                          d="m119.761 392.239c-5.857-5.858-15.355-5.858-21.213 0l-94.154
                  94.155c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.767 4.393 10.606 4.393s7.678-1.464
                  10.606-4.394l94.154-94.154c5.859-5.858 5.859-15.355.001-21.213z"
                        />
                        <path
                          d="m143.429 437.12-48.973
                  48.973c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464
                  10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.212
                  0z"
                        />
                      </svg>
                    </p>
                    <p className="leading-5 font-bold mb-2">Values</p>
                    <p className="text-sm text-gray-900 max-w-md sm:mx-auto mb-3">
                      Paxcrypt&apos;s values are rooted in efficiency, transparency
                      and inclusivity. This is reflected in our fully-automated
                      products, transparent processes and our extensive efforts
                      to develop a truly global service that makes wealth
                      creation opportunities available to everyone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h4  className="text-2xl text-center bg-white">
              Memberships
            </h4>
            <div className="flex flex-row gap-4 bg-white items-center justify-around p-16 flex-wrap">
              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/ec3cd519b9-1643360877/logo-bitcoin-foundation.svg"
                srcSet="https://nexo.io/media/pages/about-us/ec3cd519b9-1643360877/logo-bitcoin-foundation.svg 121w, https://nexo.io/media/pages/about-us/ec3cd519b9-1643360877/logo-bitcoin-foundation.svg 121w"
              />

              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/0e119aa2c8-1643360877/logo-crypto-valley.svg"
                srcSet="https://nexo.io/media/pages/about-us/0e119aa2c8-1643360877/logo-crypto-valley.svg 183w, https://nexo.io/media/pages/about-us/0e119aa2c8-1643360877/logo-crypto-valley.svg 183w"
              />

              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/c8add7c563-1643360877/logo-sfta.svg"
                srcSet="https://nexo.io/media/pages/about-us/c8add7c563-1643360877/logo-sfta.svg 579w, https://nexo.io/media/pages/about-us/c8add7c563-1643360877/logo-sfta.svg 579w"
              />

              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/5046a377b1-1643360877/logo-crypto-uk-400x.png"
                srcSet="https://nexo.io/media/pages/about-us/5046a377b1-1643360877/logo-crypto-uk-400x.png 400w, https://nexo.io/media/pages/about-us/5046a377b1-1643360877/logo-crypto-uk.png 770w"
              />

              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/6800124fb4-1653488747/logo-trust.svg"
                srcSet="https://nexo.io/media/pages/about-us/6800124fb4-1653488747/logo-trust.svg 644w, https://nexo.io/media/pages/about-us/6800124fb4-1653488747/logo-trust.svg 644w"
              />

              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/9b5732e58f-1643360877/logo-swiss-fintech-400x.png"
                srcSet="https://nexo.io/media/pages/about-us/9b5732e58f-1643360877/logo-swiss-fintech-400x.png 400w, https://nexo.io/media/pages/about-us/9b5732e58f-1643360877/logo-swiss-fintech.png 962w"
              />

              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/0292a54561-1643360877/gdf-logo-combined-teal-400x.png"
                srcSet="https://nexo.io/media/pages/about-us/0292a54561-1643360877/gdf-logo-combined-teal-400x.png 400w, https://nexo.io/media/pages/about-us/0292a54561-1643360877/gdf-logo-combined-teal.png 1182w"
              />

              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/ad6edf817e-1643360877/logo-itsa-400x.png"
                srcSet="https://nexo.io/media/pages/about-us/ad6edf817e-1643360877/logo-itsa-400x.png 400w, https://nexo.io/media/pages/about-us/ad6edf817e-1643360877/logo-itsa.png 1244w"
              />

              <img
                className="w-32"
                alt=""
                src="https://nexo.io/media/pages/about-us/50fa707b4c-1643360877/efassociation-logo-transparent-400x.png"
                srcSet="https://nexo.io/media/pages/about-us/50fa707b4c-1643360877/efassociation-logo-transparent-400x.png 400w, https://nexo.io/media/pages/about-us/50fa707b4c-1643360877/efassociation_logo_transparent.png 2468w"
              />
            </div>

            <div className="w-full">
              <div className="py-10 bg-white sm:py-16 lg:py-24">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                  <div className="text-center">
                    <p className="text-3xl font-bold text-black sm:text-4xl lg:text-5xl leading-tight">
                      The best way to
                      <br />
                      store your crypto.
                    </p>
                    <div className="mt-4 text-2xl font-medium text-gray-500">
                      Do more with your crypto with Paxcrypt Wallet
                    </div>
                    <div
                      className="flex flex-col items-center justify-center sm:space-y-0 sm:space-x-4 sm:flex-row lg:mt-12 sm:px-0
                px-16 space-y-4 mt-8"
                    >
                      <div className="inline-flex relative group">
                        <div
                          className="absolute duration-1000 rotate-180 transition-all opacity-70 -inset-px rounded-xl blur-lg
                    filter group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200"
                        ></div>
                        <NavLink
                          to="/sign-up"
                          className="inline-flex justify-center items-center w-full px-8 py-4 text-base font-semibold
                    text-white bg-gray-900 sm:w-auto relative transition-all duration-200 border border-tansparent
                    rounded-md hover:bg-gray-700 focus:bg-gray-700"
                        >
                          Get started
                        </NavLink>
                      </div>
                    </div>
                    <p className="text-base text-black mt-6">
                      Terms and conditions apply.
                      <NavLink
                        to="/terms-and-conditions"
                        title=""
                        className="text-blue-600 transition-all duration-200 hover:text-blue-700
                  focus:text-blue-700 hover:underline"
                      >
                        Learn more
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
