export const validateName = (value) => {
  let error;
  if (!value) {
    error = "Name is required";
  } else if (value.length < 4) {
    error = "Name should be atleast 4 characters";
  }
  return error;
};
export const validateSSN = (value) => {
  let error;
  if (!value) {
    error = "SSN is required";
  } else if (value.length !== 9 ) {
    error = "Enter a valid SSN";
  }
  return error;
};

export const validateBalance = (value, bal) => {
  let error;
  if (value > bal) {
    error = "Insufficient Balance";
  }
  return error;
};

export const validateEmail = (value) => {
  let error;
  let regex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  );

  if (!value) {
    error = "Email is required";
  } else if (value && !regex.test(value)) {
    error = "Enter a valid email address";
  }
  return error;
};

export const validatePassword = (value) => {
  let error;
  if (!value) {
    error = "Password is required";
  } else if (value.length < 8) {
    error = "Password should be atleast 8 characters";
  }
  return error;
};

export const validateConfirmPassword = (pass, value) => {
  let error = "";
  if (pass && value) {
    if (pass !== value) {
      error = "Passwords do not match";
    }
  }
  return error;
};
