// "use client";
import React, { useEffect } from "react";
import ResponsiveDrawer from "../../components/Drawer";
import { useAuth } from "../../context/auth";
import LoadingScreen from "../../components/LoadingScreen";
import { Navigate } from "react-router-dom";
import { getPrice } from "../../api/balance";
import Cookies from "js-cookie";
const Dashboard = () => {
  useEffect(() => {
    async function loadUserFromCookies() {
      console.log("Dashboard");
      const token = Cookies.get("token");
      if (token) {
        const data = await getPrice(token);
        console.log(data)
        // if (_stakes) setStakes(_stakes.data);
      }
      // setStakesLoading(false);
    }
    loadUserFromCookies();
  }, []);
  const { loading, isAuthenticated } = useAuth();
  if (loading) {
    return <LoadingScreen loading={loading} />;
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return <ResponsiveDrawer></ResponsiveDrawer>;
};

export default Dashboard;
