import React from "react";
import Faq from "react-faq-component";

const data = {
  title: "OTC FAQ",
  rows: [
    {
      title: "What cryptocurrencies do we support?",
      content: `All asset pairs including stable coins listed on Paxcrypt (subject to geographic restrictions)`,
    },
    {
      title: "What is the minimum trade size?",
      content:
        "The minimum trade amount is an equivalent value of $5,000 USD (Can be adjusted in special cases). There is no maximum size for trading over chat.",
    },
    {
      title: "Is there a fee?",
      content: `Paxcrypt OTC Desk does not charge any fees. The bid or offer price we show is the 'all-inclusive' price.`,
    },
    {
      title: "How do I get started?",
      content: `Contact the support team using the support widget on the bottom right of your screen or send us an email`,
    },
    {
      title: "How does settlement work?",
      content: (
        <div>
          <ul class="ml-4 list-disc ">
            <li class="text-indigo-500">
              <span class="text-black">
                Trade obligations must be settled within 24 hours
              </span>
            </li>
            <li class="text-indigo-500">
              <span class="text-black">
                Crypto can be settled to/from your Paxcrypt exchange wallet or
                sent to the external wallet of your choice
              </span>
            </li>
            <li class="text-indigo-500">
              <span class="text-black">
                USD can be settled to/from your Paxcrypt account or wired
                directly to your bank account
              </span>
            </li>
            <li class="text-indigo-500">
              <span class="text-black">
                AUD can be settled directly to/from your domestic bank account
              </span>
            </li>
            <li class="text-indigo-500">
              <span class="text-black">
                Other fiat currencies available for trading on Paxcrypt must be
                settled to your Paxcrypt account
              </span>
            </li>
          </ul>

          <p>
            Flexible settlement arrangements allow you to settle trades with
            your Paxcrypt account, your bank, or an external wallet of your
            choice within 24 hours
          </p>
        </div>
      ),
    },
  ],
};

const styles = {
  // bgColor: 'white',
  //   titleTextColor: "blue",
  //   rowTitleColor: "blue",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

export default function OTCFAQ() {
  return (
    <div>
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
}
