import Layout from "../../components/Layout";

export default function Careers() {
  return (
    <Layout>
      <main>
        <img className=" w-full" src="/banner.jpeg" alt="image" />
        <h1 className="text-center text-blue-500 text-5xl my-16">
          Come work with us and be a part of the crypto revolution!
        </h1>
        <div className="text-center my-16">
          <b>Thanks for your interest in Paxcrypt.</b>
          <br />
          We're sorry, but there are currently no open positions.
          <br />
          Contact us on our website.
        </div>
      </main>
    </Layout>
  );
}
