import React, { useState, useRef } from "react";
import Container from "@mui/material/Container";
import { useAuth } from "../../context/auth";
import { CircularProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import NotificationSystem from "react-notification-system";
import LoadingScreen from "../LoadingScreen";
import { NavLink } from "react-router-dom";
import FormError from "../FormError";
import { VerifyKYC } from "../../api/auth";
import Cookies from "js-cookie";
import { validateSSN } from "../../utils/FormValidations";

export default function KYC() {
  const notificationSystem = useRef();

  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level,
    });
  };

  const [buttonState, setButtonState] = useState(false);
  const { user, loading } = useAuth();
// console.log(user)
  const sendKYC = async (values) => {
    const formData = new FormData();
    let licenseFront = values.licenseFront;
    let licenseBack = values.licenseBack;

    if (licenseFront) formData.append("license_front", licenseFront);
    if (licenseBack) formData.append("license_back", licenseBack);

    for (const key in values) {
      if (key !== "license_front" && key !== "license_back") {
        formData.append(`${key}`, values[key]);
      }
    }

    try {
      const token = Cookies.get("token");
      await VerifyKYC(token, formData);
      setButtonState(false);
      addNotification({
        title: "Success",
        message: "KYC uploaded successfully, check for your status soon.",
        level: "success",
      });
    } catch (err) {
      setButtonState(false);
      if (!err.response.data.username) {
        addNotification({
          title: "Error",
          message: "An error has occurred",
          level: "error",
        });
      } else {
        addNotification({
          title: "Error",
          message: err.response.data.username,
          level: "error",
        });
      }
    }
  };
  if (loading) return <LoadingScreen loading={loading} />;
  return (
    <>
      <NotificationSystem ref={notificationSystem} />
      <div className="flex flex-col max-w-sm  mb-8 border bg-black border-gray-500 rounded text-white">
        <h3 className="p-4 border-b border-gray-500 text-lg font-semibold">
          My Stakings
        </h3>
        <NavLink to="/staking" className="p-4 flex gap-3 hover:bg-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            height="48"
            width="48"
            data-element="Learn"
          >
            <path
              fill="#BFE9FF"
              d="M30 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
            ></path>
            <path
              fill="#56B4FC"
              d="M3 24c0-8.475 7.05-15.3 15.525-15C26.7 9.3 33 16.2 33 24.375V48H15v-9h-4.5c-1.65 0-3-1.35-3-3v-6H3v-6z"
            ></path>
            <path
              fill="#1652F0"
              d="M18.525 9c-.825 0-1.575 0-2.325.15-.75 1.8-1.2 3.825-1.2 5.85 0 8.25 6.75 15 15 15 1.05 0 2.025-.075 3-.3v-5.325C33 16.2 26.7 9.3 18.525 9z"
            ></path>
            <path
              fill="#fff"
              d="m24 13.5 1.05 3.45L28.5 18l-3.45 1.05L24 22.5l-1.05-3.45L19.5 18l3.45-1.05L24 13.5z"
            ></path>
          </svg>
          <div>
            <h4>Learn about Staking</h4>
            <p className="text-gray-500">See how we calculate rewards</p>
          </div>
        </NavLink>
      </div>
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
        className="flex justify-center items-center px-0 mb-36"
      >
        {user.kyc ? (
          <div className="w-full">
            <div className="text-green-500 cursor-pointer hover:bg-green-900 text-lg border-green-500 border border-dashed p-3 w-full rounded-md flex items-center justify-center">
              KYC status verified
            </div>
            <p className="text-green-500 text-sm">
              *If any of your details have changed, please contact support.
            </p>
          </div>
        ) : (
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              address_line_1: "",
              address_line_2: "",
              country: "",
              zip_code: "",
              city: "",
              state: "",
              phone_number: "",
              ssn: "",
              licenseFront:null,
              licenseBack:null,
            }}
            onSubmit={(values) => {
              setButtonState(true);
              sendKYC(values);
            }}
          >
            {({ errors, touched, handleChange, handleBlur, setFieldValue }) => (
              <Form className="form flex flex-col p-8 bg-black border border-gray-500 rounded-sm w-full">
                <h3 className="text-white text-lg">Verify KYC</h3>
                <div className="flex lg:flex-row flex-col mt-4 gap-3 items-between">
                  <div className="lg:w-2/4  ">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Firstname
                    </p>
                    <Field
                      type="text"
                      required
                      name="first_name"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="John"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError
                      error={errors.first_name}
                      touched={touched.first_name}
                    />
                  </div>
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Lastname
                    </p>
                    <Field
                      required
                      type="text"
                      name="last_name"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="Doe"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError
                      error={errors.last_name}
                      touched={touched.last_name}
                    />
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col gap-3 mt-4 items-between">
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Last 4 digits of SSN
                    </p>
                    <Field
                      type="text"
                      required
                      name="ssn"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="5297"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      validate={validateSSN}
                    />
                    <FormError error={errors.ssn} touched={touched.ssn} />
                  </div>
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm  mr-0 mb-1 ml-0 block">
                      Phone Number
                    </p>
                    <Field
                      required
                      type="text"
                      name="phone_number"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="+13456545633"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError
                      error={errors.phone_number}
                      touched={touched.phone_number}
                    />
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col gap-3 mt-4 items-between">
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Address Line 1
                    </p>
                    <Field
                      type="text"
                      required
                      name="address_line_1"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="Apartment 5"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError
                      error={errors.address_line_1}
                      touched={touched.address_line_1}
                    />
                  </div>
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Address Line 2
                    </p>
                    <Field
                      required
                      type="text"
                      name="address_line_2"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="macwill street"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError
                      error={errors.address_line_2}
                      touched={touched.address_line_2}
                    />
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col gap-3 mt-4 items-between">
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm  mr-0 mb-1 ml-0 block">
                      Country
                    </p>
                    <Field
                      type="text"
                      required
                      name="country"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="USA"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError
                      error={errors.country}
                      touched={touched.country}
                    />
                  </div>
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      Zip code
                    </p>
                    <Field
                      required
                      type="text"
                      name="zip_code"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="20034"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError
                      error={errors.zip_code}
                      touched={touched.zip_code}
                    />
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col gap-3 mt-4 items-between">
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm  mr-0 mb-1 ml-0 block">
                      State
                    </p>
                    <Field
                      type="text"
                      required
                      name="state"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="California"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError error={errors.state} touched={touched.state} />
                  </div>
                  <div className="lg:w-2/4">
                    <p className="text-gray-200 font-medium text-sm mr-0 mb-1 ml-0 block">
                      City
                    </p>
                    <Field
                      required
                      type="text"
                      name="city"
                      className="border border-blue-400 w-full rounded-md 
                              hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                      placeholder="New york"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormError error={errors.city} touched={touched.city} />
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col gap-3 items-between mt-4">
                  <div className="lg:w-2/4">
                    <label
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      htmlFor="licenseFront"
                    >
                      Front of Valid ID
                    </label>
                    <input
                      class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      id="licenseFront"
                      name="licenseFront"
                      required
                      onChange={(event) => {
                        setFieldValue(
                          "licenseFront",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                  </div>
                  <div className="lg:w-2/4">
                    <label
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      htmlFor="licenseBack"
                    >
                      Back of Valid ID
                    </label>
                    <input
                      class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      id="licenseBack"
                      name="licenseBack"
                      required
                      onChange={(event) => {
                        setFieldValue(
                          "licenseBack",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                                text-white bg-blue-500 hover:bg-blue-700 cursor-pointer"
                >
                  {buttonState ? (
                    <CircularProgress className="text-white" size={25} />
                  ) : (
                    "Verify KYC"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </>
  );
}
