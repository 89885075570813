import { Typography } from "@mui/material";
import Layout from "../../components/Layout";


export default function Referral() {
  return (
    <Layout>
      <div>
        <div className=" ">
          <div className="bg-white">
            <div className="bg-transparent relative mr-auto ml-auto">
              <div
                className="bg-transparent pt-16 pr-4 pb-16 pl-4 mr-auto ml-auto sm:max-w-xl md:max-w-full lg:px-8 md:px-24
            lg:max-w-screen-xl lg:py-20"
              >
                <div className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row">
                  <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
                    <div className="mb-6 max-w-xl">
                      <p
                        className="inline-block pt-1 pr-3 pb-1 pl-3 mb-4 text-pink-200 bg-pink-500 rounded-2xl uppercase text-xs
                    font-semibold leading-wider"
                      >
                        New!!
                      </p>
                      <div className="text-3xl font-bold leading-tight text-white max-w-lg sm:text-4xl sm:leading-none mb-6">
                        <p className="text-black text-3xl font-bold leading-tight sm:text-4xl sm:leading-none">
                          Refer your friends
                        </p>
                        <p
                          className="inline-block text-black text-3xl font-bold leading-tight mr-3 sm:text-4xl
                      sm:leading-none"
                        >
                          and family to
                        </p>
                        <p
                          className="inline-block text-green-700 text-3xl font-bold leading-tight sm:text-4xl
                      sm:leading-none"
                        >
                          earn crypto!
                        </p>
                      </div>
                      <p className="text-gray-700 bg-transparent text-base mb-4 md:text-lg">
                        Help us introduce the world to bitcoin, cryptocurrency,
                        and the new financial system.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center lg:w-1/2">
                    <div className="w-2/5 ml-8 z-10">
                      <img
                        src="https://nexo.io/media/pages/referral/3e32876041-1658919160/increased-limits-x2.png"
                        className="object-cover"
                        alt="sbf"
                      />
                    </div>
                  </div>
                </div>
                <div
                  aria-label="Scroll down"
                  className="flex items-center justify-center w-10 h-10 mr-auto ml-auto text-gray-600
              border-sm border-gray-400 rounded-full transform duration-300 hover:text-blue-700 hover:border-blue-700
              hover:shadow hover:scale-110"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                  >
                    <path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-white pt-16 pr-4 pb-16 pl-4 mt-0 mr-auto mb-0 ml-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl
        md:px-24 lg:px-8 lg:py-20"
          >
            <div className="grid sm:grid-cols-3 gap-5 row-gap-5">
              <div className="pt-0 pr-12 pb-0 pl-12 text-center sm:px-0">
                <p className="leading-5 text-sm font-bold tracking-wider mb-2 uppercase">
                  Become a Paxcrypt Referrer
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  $250 for you, $250 for your friends.
                </p>
              </div>
              <div className="pt-0 pr-12 pb-0 pl-12 text-center sm:px-0">
                <p className="leading-5 text-sm font-bold tracking-wider mb-2 uppercase">
                  Promote Paxcrypt
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  Make unlimited referrals.
                </p>
              </div>
              <div className="pt-0 pr-12 pb-0 pl-12 text-center sm:px-0">
                <p className="leading-5 text-sm font-bold tracking-wider mb-2 uppercase">
                  Earn Crypto Rewards
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  The more you refer, the more Crypto you get.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
            <div className="bg-white mr-auto ml-auto lg:max-w-screen-xl sm:max-w-xl md:max-w-full">
              <div className="mr-auto ml-auto max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div>
                  <p
                    className="inline-block px-4 py-2 text-xs font-semibold text-white bg-green-500 rounded-full tracking-wider
                uppercase"
                  >
                    new
                  </p>
                </div>
                <div
                  className="mr-auto ml-auto text-3xl font-bold leading-none text-gray-900 max-w-lg font-sans tracking-tight
              sm:text-4xl md:mx-auto"
                >
                  <div className="inline-block relative">
                    <p
                      className="w-32 -mt-8 -ml-20 text-blue-gray-100 absolute top-0 left-0 z-0 hidden lg:w-32 lg:-ml-28
                  lg:-mt-10 sm:block"
                    >
                      <svg viewBox="0 0 52 24" fill="currentColor">
                        <defs>
                          <pattern x="0" y="0" width=".135" height=".30">
                            <circle cx="1" cy="1" r=".7" />
                          </pattern>
                        </defs>
                        <rect
                          fill="url(#d0d83814-78b6-480f-9a5f-7f637616b267)"
                          width="52"
                          height="24"
                        />
                      </svg>
                    </p>
                  </div>
                  <p className="text-3xl inline font-bold leading-none text-gray-900 fontsans">
                    How it works
                  </p>
                </div>
                <p className="text-gray-700 md:text-lg">
                  Join our referral program and earn crypto by promoting
                  Paxcrypt.
                </p>
              </div>
              <div className="grid gap-8 row-gap-5 md:row-gap-8 lg:grid-cols-3">
                <div
                  className="bg-white p-4 border-2 rounded shadow-sm duration-300 transform border-dashed border-green-100
              hover:-translate-y-2"
                >
                  <div className="flex items-center mb-2">
                    <div className="flex items-center mr-2 justify-center w-10 h-10 mb-2 rounded-full bg-green-400">
                      <p className="text-lg  font-bold text-white">1</p>
                    </div>
                    <p className="text-lg font-bold leading-5">
                      Become a Paxcrypt Referrer
                    </p>
                  </div>
                  <p className="text-gray-900 text-sm">
                    $250 for you, $250 for your friends.
                  </p>
                </div>
                <div
                  className="bg-white p-4 border-2 rounded shadow-sm duration-300 transform border-dashed border-green-100
              hover:-translate-y-2"
                >
                  <div className="flex items-center mb-2">
                    <div className="flex mr-2 items-center justify-center w-10 h-10 mb-2 rounded-full bg-green-400">
                      <p className="text-lg font-bold text-white">2</p>
                    </div>
                    <p className="text-lg font-bold leading-5">
                      Promote Paxcrypt
                    </p>
                  </div>
                  <p className="text-gray-900 text-sm">
                    Make unlimited referrals.
                  </p>
                </div>
                <div
                  className="bg-white p-4 border-2 rounded shadow-sm duration-300 transform border-dashed border-green-100
              hover:-translate-y-2"
                >
                  <div className="flex items-center mb-2">
                    <div className="flex mr-2 items-center justify-center w-10 h-10 mb-2 rounded-full bg-green-400">
                      <p className="text-lg font-bold text-white">3</p>
                    </div>
                    <p className="text-lg font-bold leading-5">
                      Earn Crypto Rewards
                    </p>
                  </div>
                  <p className="text-gray-900 text-sm">
                    The more you refer, the more Crypto you get.
                  </p>
                  <div
                    className="flex items-center justify-center w-8 h-8 font-bold rounded-full bg-green-400 absolute top-0
                right-0 sm:-mt-5 sm:-mr-5 sm:w-10 sm:h-10"
                  >
                    <p className="text-white w-7">
                      <svg stroke="currentColor" viewBox="0 0 24 24">
                        <polyline
                          fill="none"
                          strokeWidth="2"
                          strokelinecap="round"
                          strokelinejoin="round"
                          strokemiterlimit="10"
                          points="6,12 10,16 18,8"
                        />
                      </svg>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="container mx-auto my-12 md:my-32">
            <div class="mb-12 text-center">
              <Typography variant="h5">As Featured in </Typography>
            </div>
            <div class="mx-auto">
              <div class="flex flex-wrap justify-center items-center md:m-16 text-24 md:text-32 text-center">
                <a
                  class="inline-block the-wsj-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    // style={{ height: "0.84em" }}
                    alt=""
                    height="29"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/f2c611a0b6-1659093595/wsj-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/f2c611a0b6-1659093595/wsj-grey.svg 53w, https://nexo.io/media/pages/storage/logos/f2c611a0b6-1659093595/wsj-grey.svg 53w"
                    width="100px"
                  />
                </a>
                <a
                  class="inline-block the-the-washington-post-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    // style={{ height: "1.2em" }}
                    alt=""
                    height="40"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/e358fa9225-1659093595/the-washington-post-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/e358fa9225-1659093595/the-washington-post-grey.svg 255w, https://nexo.io/media/pages/storage/logos/e358fa9225-1659093595/the-washington-post-grey.svg 255w"
                    width="100px"
                  />
                </a>
                <a
                  class="inline-block the-reuters-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    // style={{ height: "0.98em" }}
                    alt=""
                    height="1000"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/263aad8539-1659093595/reuters-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/263aad8539-1659093595/reuters-grey.svg 139w, https://nexo.io/media/pages/storage/logos/263aad8539-1659093595/reuters-grey.svg 139w"
                    width="300px"
                  />
                </a>
                <a
                  class="inline-block the-bloomberg-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    // style={{ height: "0.84em" }}
                    alt=""
                    height="64"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/811dc2c234-1651588486/bloomberg-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/811dc2c234-1651588486/bloomberg-grey.svg 346w, https://nexo.io/media/pages/storage/logos/811dc2c234-1651588486/bloomberg-grey.svg 346w"
                    width="346"
                  />
                </a>
                <a
                  class="inline-block the-fortune-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    // style={{ height: "0.78em" }}
                    alt=""
                    height="28"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/6c3e53b299-1659093595/fortune-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/6c3e53b299-1659093595/fortune-grey.svg 114w, https://nexo.io/media/pages/storage/logos/6c3e53b299-1659093595/fortune-grey.svg 114w"
                    width="114"
                  />
                </a>
                <a
                  class="inline-block the-cnbc-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    // style={{ height: "0.83em" }}
                    alt=""
                    height="28"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/98250182dc-1659093595/cnbc-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/98250182dc-1659093595/cnbc-grey.svg 134w, https://nexo.io/media/pages/storage/logos/98250182dc-1659093595/cnbc-grey.svg 134w"
                    width="134"
                  />
                </a>
                <a
                  class="inline-block the-coindesk-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    // style={{ height: "0.98em" }}
                    alt=""
                    height="64"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/fb49c6db18-1651588486/coindesk-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/fb49c6db18-1651588486/coindesk-grey.svg 332w, https://nexo.io/media/pages/storage/logos/fb49c6db18-1651588486/coindesk-grey.svg 332w"
                    width="332"
                  />
                </a>
                <a
                  class="inline-block the-the-block-gery m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    alt=""
                    height="28"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/72047f723b-1659093595/the-block-gery.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/72047f723b-1659093595/the-block-gery.svg 157w, https://nexo.io/media/pages/storage/logos/72047f723b-1659093595/the-block-gery.svg 157w"
                    width="157"
                  />
                </a>
                <a
                  class="inline-block the-cointelegraph-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    alt=""
                    height="64"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/432e21d3b6-1651588486/cointelegraph-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/432e21d3b6-1651588486/cointelegraph-grey.svg 248w, https://nexo.io/media/pages/storage/logos/432e21d3b6-1651588486/cointelegraph-grey.svg 248w"
                    width="248"
                  />
                </a>
                <a
                  class="inline-block the-decrypt-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="m-4"
                    alt=""
                    height="64"
                    loading="lazy"
                    src="https://nexo.io/media/pages/storage/logos/23fb69055a-1659093595/decrypt-grey.svg"
                    srcset="https://nexo.io/media/pages/storage/logos/23fb69055a-1659093595/decrypt-grey.svg 97w, https://nexo.io/media/pages/storage/logos/23fb69055a-1659093595/decrypt-grey.svg 97w"
                    width="197"
                  />
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}
