import React, { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

//api here is an axios instance which has the baseURL set according to the env.
import { getUser } from "../api/auth";
import LoadingScreen from "../components/LoadingScreen";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  let location = useLocation();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("token");
      if (token) {
        const response = await getUser(token)
          .then((res) => {
            if (res.data) setUser(res.data);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              navigate("/verify-email");
            }
            if (err.response.status === 401) {
              navigate("/login");
            }
          });
      }
      setLoading(false);
    }
    if (
      location.pathname === "/dashboard" ||
      location.pathname === "/verify-email"
    )
      loadUserFromCookies();
  }, []);

  // const login = async (email, password) => {
  //     const { data: token } = await api.post('auth/login', { email, password })
  //     if (token) {
  //         console.log("Got token")
  //         Cookies.set('token', token, { expires: 60 })
  //         api.defaults.headers.Authorization = `Bearer ${token.token}`
  //         const { data: user } = await api.get('users/me')
  //         setUser(user)
  //         console.log("Got user", user)
  //     }
  // }

  const logout = (email, password) => {
    Cookies.remove("token");
    setUser(null);
    window.location.pathname = "/login";
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, loading, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" />;
  }
  if (loading || (!isAuthenticated && window.location.pathname !== "/login")) {
    return (
      <LoadingScreen
        loading={
          loading || (!isAuthenticated && window.location.pathname !== "/login")
        }
      />
    );
  }
  return children;
};
