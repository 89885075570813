import React, { useState } from "react";
import Cookies from "js-cookie";
import { Formik, Form, Field } from "formik";
import Layout from "../../components/Layout";
import { getOTP, OTPVerify } from "../../api/auth";
import { validateEmail } from "../../utils/FormValidations";
import { CircularProgress } from "@mui/material";
import { useNotification } from "../../context/notif";
import { useAuth } from "../../context/auth";
import LoadingScreen from "../../components/LoadingScreen";

export default function VerifyEmail() {
  const { addNotification } = useNotification();
  const [authError, setAuthError] = useState(null);
  const [userMail, setUserMail] = useState("");
  const { user, loading } = useAuth();
  const [buttonState, setButtonState] = useState(false);
  const [mailsent, setMailSent] = useState(false);
  const [OTPLoading, setOTPLoading] = useState(false);

  const sendOTP = async (values) => {
    setOTPLoading(true);
    let email = values.email;
    try {
      const response = await getOTP(JSON.stringify({ email }));
      if (response.status === 200) {
        setUserMail(email);
        addNotification({
          title: "Success",
          message: "OTP sent successfully, check your mail",
          level: "success",
        });
        setMailSent(true);
      }
      setButtonState(false);
      setOTPLoading(false);
    } catch (err) {
      setButtonState(false);
      if (err.response.data.message) {
        addNotification({
          title: "Error",
          message: err.response.data.message,
          level: "error",
        });
        setAuthError(err.response.data.message);
      } else {
        addNotification({
          title: "Error",
          message: "An unexpected error has occured",
          level: "error",
        });
        setAuthError("An Error has occured, please try again");
      }
      setOTPLoading(false);
    }
  };
  const verifyOTP = async (values) => {
    let otp = values.otp;
    try {
      const token = Cookies.get("token");
      const response = await OTPVerify(
        token,
        JSON.stringify({ token: otp, email: userMail })
      )
        .then((res) => {
          if (res.status === 200) {
            addNotification({
              title: "Success",
              message: "Account verified",
              level: "success",
            });
            window.location.pathname = "/login";
          }
        })
        .catch((err) => {
          if (err.response.status === 405) {
            addNotification({
              title: "Error",
              message: "Invalid OTP",
              level: "error",
            });
          }
        });
      setButtonState(false);
    } catch (err) {
      setButtonState(false);
      addNotification({
        title: "Error",
        message: "An unexpected error has occured",
        level: "error",
      });
      if (!err?.response) {
        setAuthError("No Server Response");
      } else {
        setAuthError("An unexpected error has occured");
      }
    }
  };
  if (loading || OTPLoading)
    return <LoadingScreen loading={loading || OTPLoading} />;
  if (user)
    return (
      <Layout>
        <main>
          <p className="font-semibold text-4xl text-gray-900 mt-36 mb-36 text-center">
            Your Email has been verified
          </p>
        </main>
      </Layout>
    );
  return (
    <Layout>
      <main>
        <div>
          <div className=" ">
            <div className="pt-24 pr-4 pb-24 pl-4">
              <div className="max-w-7xl mt-0 mr-auto mb-0 ml-auto">
                {!!mailsent ? (
                  <div className="w-full mt-0 mr-auto mb-0 ml-auto space-y-5 sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12">
                    <p className="font-semibold text-4xl text-gray-900 mb-4 text-center">
                      Enter OTP
                    </p>
                    <Formik
                      initialValues={{
                        otp: "",
                      }}
                      onSubmit={(values) => {
                        setButtonState(true);
                        verifyOTP(values);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        values,
                        validateOnChange,
                        /* and other goodies */
                      }) => (
                        <Form className="form">
                          <div className="block">
                            <p className="text-gray-700 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                              Verify OTP
                            </p>
                            <Field
                              type="text"
                              name="otp"
                              className=" w-full rounded-md border-2
                            hover:border-purple-700 pt-2 pr-3 pb-2 pl-3"
                              placeholder="89768"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <div className="text-red-400">
                              {errors.otp && touched.otp && errors.otp}
                            </div>
                          </div>
                          <div className="form-submit">
                            {buttonState && (
                              <div
                                className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-700 hover:bg-blue-900 flex justify-center items-center"
                              >
                                <CircularProgress size={25} />
                              </div>
                            )}
                            {!buttonState && (
                              <input
                                className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-700 hover:bg-blue-900"
                                type="submit"
                                disabled={buttonState}
                                value="Verify"
                              />
                            )}
                          </div>
                          <div className="text-red-400 mt-4">
                            {authError ? <p>{authError} </p> : null}
                          </div>

                          <span className="sign-up-redirect">
                            Did&apos;t receive Email?{" "}
                            <span>
                              <a
                                href="/sign-up"
                                className="text-sm text-gray-700 underline hover:text-purple-700"
                              >
                                Resend
                              </a>
                            </span>
                          </span>
                        </Form>
                      )}
                    </Formik>
                  </div>
                ) : (
                  <div className="w-full mt-0 mr-auto mb-0 ml-auto space-y-5 sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12">
                    <p className="font-semibold text-4xl text-gray-900 mb-4 text-center">
                      Verify Email
                    </p>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      onSubmit={(values) => {
                        setButtonState(true);
                        sendOTP(values);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        values,
                        validateOnChange,
                        /* and other goodies */
                      }) => (
                        <Form className="form">
                          <div className="block">
                            <p className="text-gray-700 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                              Email
                            </p>
                            <Field
                              type="email"
                              name="email"
                              className=" w-full rounded-md border-2
                            hover:border-purple-700 pt-2 pr-3 pb-2 pl-3"
                              placeholder="buv@gmail.com"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              validate={validateEmail}
                            />
                            <div className="text-red-400">
                              {errors.email && touched.email && errors.email}
                            </div>
                          </div>
                          <div className="form-submit">
                            {buttonState && (
                              <div
                                className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-700 hover:bg-blue-900 flex justify-center items-center"
                              >
                                <CircularProgress size={25} />
                              </div>
                            )}
                            {!buttonState && (
                              <input
                                className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-700 hover:bg-blue-900"
                                type="submit"
                                disabled={buttonState}
                                value="Verify"
                              />
                            )}
                          </div>
                          <div className="text-red-400 mt-4">
                            {authError ? <p>{authError} </p> : null}
                          </div>

                          <span className="sign-up-redirect">
                            Don&apos;t have an Account?{" "}
                            <span>
                              <a
                                href="/verify-email"
                                className="text-sm text-gray-700 underline hover:text-purple-700"
                              >
                                Sign Up
                              </a>
                            </span>
                          </span>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
