import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import FAQ from "../../components/FAQ";
import Layout from "../../components/Layout";
import face from "./face.png";
import app from "./app.png";
export default function Home() {
  return (
    <Layout>
      <main id="__next">
        <div>
          <div>
            <div className=" ">
              <div className="bg-white pr-4 pl-4 lg:pt-16 md:px-8">
                <div
                  className="flex flex-col justify-between mr-auto ml-auto bg-white w-full lg:items-center lg:flex-row lg:max-w-screen-xl
          xl:max-w-screen-xl max-w-screen-2xl"
                >
                  <div className="pt-16 mb-16 lg:mb-0 lg:pt-32 lg:max-w-lg lg:pl-5">
                    <div className="mb-6 max-w-xl">
                      <p
                        className="inline-block pt-1 pr-3 pb-1 pl-3 mb-4 text-pink-200 bg-pink-500 rounded-2xl uppercase text-xs
                font-semibold leading-wider"
                      >
                        Secure Web Wallet
                      </p>
                      <div className="text-3xl font-bold leading-tight text-white max-w-lg sm:text-4xl sm:leading-none mb-4">
                        <p className="text-gray-900 text-3xl font-bold leading-tight mb-4 sm:text-4xl sm:leading-none">
                          Explore digital currency like Bitcoin, Ethereum, and Dogecoin.
                        </p>
                      </div>
                      <p className="text-gray-700 bg-transparent text-base mb-4 md:text-lg">
                        Simply and securely buy, sell, stake and manage
                        different digital assets.
                      </p>
                    </div>
                    <div className="flex items-center">
                      <NavLink
                        to="/sign-up"
                        className="transition duration-200  hover:bg-blue-900 focus:shadow-outline focus:outline-none
                bg-blue-700 text-white inline-flex font-bold leading-wide text-medium h-12 items-center justify-center
                pr-6 pl-6 mr-6 shadow-md rounded-lg"
                      >
                        Get started
                      </NavLink>
                      <NavLink
                        to="/about"
                        className="transition-colors duration-200 hover:bg-blue-900 hover:text-white bg-white text-blue-700
                inline-flex font-semibold leading-wide text-medium h-12 items-center justify-center pr-6 pl-6 mr-6
                rounded-lg"
                      >
                        Learn more
                      </NavLink>
                    </div>
                  </div>
                  <img
                    className="lg:h-auto
                    xl:mr-24 md:max-w-lg btn- w-full mr-auto ml-auto"
                    src={app}
                    alt="banner"
                  />
  
                </div>
              </div>
              <section className="container mx-auto my-12 md:my-32">
                <div className="mb-12 text-center">
                  <Typography variant="h5">As Featured in </Typography>
                </div>
                <div className="mx-auto">
                  <div className="flex flex-wrap justify-center items-center md:m-16 text-24 md:text-32 text-center">
                    <a
                      className="inline-block the-wsj-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        // style={{ height: "0.84em" }}
                        alt="erg"
                        height="29"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/f2c611a0b6-1659093595/wsj-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/f2c611a0b6-1659093595/wsj-grey.svg 53w, https://nexo.io/media/pages/storage/logos/f2c611a0b6-1659093595/wsj-grey.svg 53w"
                        width="100px"
                      />
                    </a>
                    <a
                      className="inline-block the-the-washington-post-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        // style={{ height: "1.2em" }}
                        alt="ugy"
                        height="40"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/e358fa9225-1659093595/the-washington-post-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/e358fa9225-1659093595/the-washington-post-grey.svg 255w, https://nexo.io/media/pages/storage/logos/e358fa9225-1659093595/the-washington-post-grey.svg 255w"
                        width="100px"
                      />
                    </a>
                    <a
                      className="inline-block the-reuters-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        // style={{ height: "0.98em" }}
                        alt="uyg"
                        height="1000"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/263aad8539-1659093595/reuters-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/263aad8539-1659093595/reuters-grey.svg 139w, https://nexo.io/media/pages/storage/logos/263aad8539-1659093595/reuters-grey.svg 139w"
                        width="300px"
                      />
                    </a>
                    <a
                      className="inline-block the-bloomberg-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        // style={{ height: "0.84em" }}
                        alt=""
                        height="64"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/811dc2c234-1651588486/bloomberg-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/811dc2c234-1651588486/bloomberg-grey.svg 346w, https://nexo.io/media/pages/storage/logos/811dc2c234-1651588486/bloomberg-grey.svg 346w"
                        width="346"
                      />
                    </a>
                    <a
                      className="inline-block the-fortune-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        // style={{ height: "0.78em" }}
                        alt=""
                        height="28"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/6c3e53b299-1659093595/fortune-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/6c3e53b299-1659093595/fortune-grey.svg 114w, https://nexo.io/media/pages/storage/logos/6c3e53b299-1659093595/fortune-grey.svg 114w"
                        width="114"
                      />
                    </a>
                    <a
                      className="inline-block the-cnbc-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        // style={{ height: "0.83em" }}
                        alt=""
                        height="28"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/98250182dc-1659093595/cnbc-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/98250182dc-1659093595/cnbc-grey.svg 134w, https://nexo.io/media/pages/storage/logos/98250182dc-1659093595/cnbc-grey.svg 134w"
                        width="134"
                      />
                    </a>
                    <a
                      className="inline-block the-coindesk-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        // style={{ height: "0.98em" }}
                        alt=""
                        height="64"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/fb49c6db18-1651588486/coindesk-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/fb49c6db18-1651588486/coindesk-grey.svg 332w, https://nexo.io/media/pages/storage/logos/fb49c6db18-1651588486/coindesk-grey.svg 332w"
                        width="332"
                      />
                    </a>
                    <a
                      className="inline-block the-the-block-gery m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        alt=""
                        height="28"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/72047f723b-1659093595/the-block-gery.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/72047f723b-1659093595/the-block-gery.svg 157w, https://nexo.io/media/pages/storage/logos/72047f723b-1659093595/the-block-gery.svg 157w"
                        width="157"
                      />
                    </a>
                    <a
                      className="inline-block the-cointelegraph-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        alt=""
                        height="64"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/432e21d3b6-1651588486/cointelegraph-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/432e21d3b6-1651588486/cointelegraph-grey.svg 248w, https://nexo.io/media/pages/storage/logos/432e21d3b6-1651588486/cointelegraph-grey.svg 248w"
                        width="248"
                      />
                    </a>
                    <a
                      className="inline-block the-decrypt-grey m-2 md:mx-18  transition duration-2 ease-1 transform hover:scale-110 align-middle"
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="m-4"
                        alt=""
                        height="64"
                        loading="lazy"
                        src="https://nexo.io/media/pages/storage/logos/23fb69055a-1659093595/decrypt-grey.svg"
                        srcSet="https://nexo.io/media/pages/storage/logos/23fb69055a-1659093595/decrypt-grey.svg 97w, https://nexo.io/media/pages/storage/logos/23fb69055a-1659093595/decrypt-grey.svg 97w"
                        width="197"
                      />
                    </a>
                  </div>
                </div>
              </section>
              <div className="bg-white pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
                <div className="mr-auto ml-auto w-full sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
                  <div className="mr-auto mb-10 ml-auto max-w-xl md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <div
                      className="mr-auto mb-6 ml-auto text-3xl font-bold leading-none tracking-tight text-gray-900 max-w-lg
              font-sans sm:text-4xl md:mx-auto"
                    >
                      <div className="inline relative">
                        <p className="inline">
                          <svg
                            viewBox="0 0 52 24"
                            fill="currentColor"
                            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20
                    text-blue-700 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                          >
                            <defs>
                              <pattern x="0" y="0" width=".135" height=".30">
                                <circle cx="1" cy="1" r=".7" />
                              </pattern>
                            </defs>
                            <rect
                              fill="url(#34f481be-159a-4846-821d-9ca19fb6bcc5)"
                              width="52"
                              height="24"
                            />
                          </svg>
                        </p>
                      </div>
                      <p className="inline font-bold text-3xl tracking-tight font-sans sm:text-4xl sm:leading-none">
                        You Deserve Easy Access to Cryptocurrencies
                      </p>
                    </div>
                    <p className="text-gray-700 text-base md:text-lg">
                      Paxcrypt Wallet is for you if you want:
                    </p>
                  </div>
                  <div className="mb-8 grid gap-8 row-gap-5 md:row-gap-8 lg:grid-cols-3 sm:grid-cols-2">
                    <div className="bg-white duration-300 transform border-r-4 border-blue-700 hover:-translate-y-2">
                      <div className="pt-5 pr-5 pb-5 pl-5 rounded-r shadow-sm h-full border border-l-0">
                        <p className="font-semibold leading-5 mb-2">Security</p>
                        <p className="text-sm text-gray-900 mb-2">
                          Our app offers top-notch security to keep your
                          cryptocurrency investments safe, including
                          multi-factor authentication, encryption, and secure
                          private key storage.
                        </p>
                      </div>
                    </div>
                    <div className="bg-white duration-300 transform border-r-4 border-blue-700 hover:-translate-y-2">
                      <div className="pt-5 pr-5 pb-5 pl-5 rounded-r shadow-sm h-full border border-l-0">
                        <p className="font-semibold leading-5 mb-2">
                          Ease of use
                        </p>
                        <p className="text-sm text-gray-900 mb-2">
                          Our user-friendly interface makes it easy to manage
                          your investments, send/receive payments, and view
                          transaction history.
                        </p>
                      </div>
                    </div>
                    <div className="bg-white duration-300 transform border-r-4 border-blue-700 hover:-translate-y-2">
                      <div className="pt-5 pr-5 pb-5 pl-5 rounded-r shadow-sm h-full border border-l-0">
                        <p className="font-semibold leading-5 mb-2">
                          Multi-currency support
                        </p>
                        <p className="text-sm text-gray-900 mb-2">
                          Manage all your investments in one place with support
                          for multiple cryptocurrencies, including Bitcoin,
                          Ethereum, and Litecoin.
                        </p>
                      </div>
                    </div>
                    <div className="bg-white duration-300 transform border-r-4 border-blue-700 hover:-translate-y-2">
                      <div className="pt-5 pr-5 pb-5 pl-5 rounded-r shadow-sm h-full border border-l-0">
                        <p className="font-semibold leading-5 mb-2">
                          Portfolio tracking
                        </p>
                        <p className="text-sm text-gray-900 mb-2">
                          Track the value of your portfolio, view performance
                          graphs, and make informed decisions with our portfolio
                          tracking features.
                        </p>
                      </div>
                    </div>
                    <div className="bg-white duration-300 transform border-r-4 border-blue-700 hover:-translate-y-2">
                      <div className="pt-5 pr-5 pb-5 pl-5 rounded-r shadow-sm h-full border border-l-0">
                        <p className="font-semibold leading-5 mb-2">
                          Customer support
                        </p>
                        <p className="text-sm text-gray-900 mb-2">
                          Our dedicated customer support team is always
                          available to assist you with any questions or issues
                          you may have.
                        </p>
                      </div>
                    </div>
                    <div className="bg-white duration-300 transform border-r-4 border-blue-700 hover:-translate-y-2">
                      <div className="pt-5 pr-5 pb-5 pl-5 rounded-r shadow-sm h-full border border-l-0">
                        <p className="font-semibold leading-5 mb-2">
                          Accessibility
                        </p>
                        <p className="text-sm text-gray-900 mb-2">
                          Stay on top of your investments with our website,
                          available anywhere, anytime.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <NavLink
                      to="/sign-up"
                      className="inline-flex w-18 h-12 text-white font-medium tracking-wide bg-blue-700 pr-6 pl-6 transition
              items-center duration-200 rounded shadow-md md:w-auto hover:bg-blue-900 focus:shadow-outline
              focus:outline-none"
                    >
                      Get Started
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="bg-white">
                <div className="items-center mr-auto ml-auto container max-w-6xl sm:px-20 md:px-32 lg:px-16">
                  <div className="flex items-center p-12 flex-wrap">
                    <div className="w-full lg:w-1/2 order-1">
                      <div className="w-full lg:max-w-md">
                        <p className="text-3xl font-bold leading-tight inline tracking-tight sm:text-4xl font-heading">
                          Your key to the world of{" "}
                        </p>
                        <p
                          className="text-3xl font-bold inline leading-tight text-blue-500 tracking-tight sm:text-4xl
                  font-heading"
                        >
                          crypto!
                        </p>
                        <p className="font-medium my-4 text-gray-600 tracking-tight xl:mb-6">
                          From Bitcoin to Litecoin, we make it easy to send and
                          receive cryptocurrency. Protect your crypto with best
                          in class cold storage.
                        </p>
                        <div>
                          <div className="flex mb-3 items-center space-x-4 ">
                            <p>✔️</p>
                            <p className="font-medium text-gray-800">
                              Store all of your crypto and NFTs in one place
                            </p>
                          </div>
                          <div className="flex mb-3 items-center space-x-4 ">
                            <p>✔️</p>
                            <p className="font-medium text-gray-800">
                              Support for hundreds of thousands of tokens and
                              dapps
                            </p>
                          </div>
                          <div className="flex my-2 items-center space-x-4 ">
                            <p>✔️</p>
                            <p className="font-medium text-gray-800">
                              Protect your digital assets with industry-leading
                              security
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full rounded-full	 lg:w-1/2 order-0 mb-12">
                      {/* <Image
                        src="/world.jpg"
                        alt="me"
                        width="360"
                        height="360"
                        className="rounded-full	"
                      /> */}
                      <img
                        className=" w-11/12"
                        src="/coinworld.png"
                        alt="coinworld"
                      />
                      {/* <img
                        src=""
                        className="sm:max-w-sm lg:max-w-full btn- mr-auto ml-auto"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="relative py-12 bg-gray-900 sm:py-16 lg:py-20">
                  <div className="absolute inset-0">
                    <img
                      alt="gwrf"
                      src="https://images.unsplash.com/photo-1621504450181-5d356f61d307?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
                      className="object-cover btn- w-full h-full"
                    />
                  </div>
                  <div className="relative max-w-7xl px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="flex items-center justify-start mx-auto max-w-6xl">
                      <div className="w-full text-center lg:text-left lg:w-1/2 rounded-xl backdrop-blur-lg bg-white/90">
                        <div className="px-10 py-12 lg:px-12 lg:py-14">
                          <p className="mt-5 text-2xl font-bold text-gray-900 sm:text-3xl lg:text-4xl">
                            The freedom of crypto
                            <br />
                            for everyone, everywhere
                          </p>
                          <p className="mt-5 text-base font-normal text-gray-600">
                            We’re committed to creating more economic freedom
                            through accessible, safe, and secure financial tools
                            for everyone.
                          </p>
                          <div className="mt-9">
                            <NavLink
                              to="/sign-up"
                              className="px-8 py-3 text-base font-bold text-white bg-gray-900 leading-7 transition-all
                      duration-200 border border-transparent rounded hover:bg-gray-700 focus:outline-none focus:ring-2
                      focus:ring-offset-2 focus:ring-gray-900 inline-flex items-center justify-center"
                            >
                              Get Started
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-900 pt-12 pr-0 pb-12 pl-0 lg:py-24 sm:py-16">
                <div className="pt-0 pr-4 pb-0 pl-4 mt-0 mr-auto mb-0 ml-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="grid items-center gap-12 grid-cols-1 sm:p-10 sm:gap-16 lg:grid-cols-2">
                    <div className="mt-0 mr-auto mb-0 ml-auto text-center lg:max-w-none max-w-md lg:text-left">
                      <p className="text-4xl text-gray-100 font-bold">
                        Safely earn with crypto🚀
                      </p>
                      <p className="leading-7 font-normal text-gray-100 text-base mt-8 mr-0 mb-0 ml-0">
                        Paxcrypt offers lots of features and our users love us!!
                      </p>
                    </div>
                    <div>
                      <div
                        className="rounded-2xl bg-gray-100 max-w-sm rotate-3 shadow-lg shadow-orange-300 mt-0 mr-auto mb-0
                ml-auto"
                      >
                        <div className="pt-6 pr-6 pb-6 pl-6 sm:px-10 sm:py-12">
                          <p className="font-normal text-lg mt-6 mr-0 mb-0 ml-0">
                            “I love the Paxcrypt! It&apos;s so easy to use with
                            a great design. Plus, having all my digital assets
                            in one place gives me one less thing to worry about.
                            Security is top-notch, making me feel confident that
                            my assets are safe. I highly recommend this
                            webwallet to anyone!”
                          </p>
                          <div className="flex items-center justify-between mt-6 mr-0 mb-0 ml-0">
                            <div className="flex items-center">

                              <img
                                className="btn- object-cover rounded-full w-11 h-11"
                                src={face}
                                alt="user "
                              />
                              <div className="mt-0 mr-0 mb-0 ml-3">
                                <p className="text-lg font-bold">Linda M.</p>
                                <p className="font-light text-sm mt-px mr-0 mb-0 ml-0">
                                  Paxcrypt User
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="py-12 bg-white sm:py-16 lg:py-20">
                  <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className="text-center">
                      <p className="text-4xl font-bold text-gray-800 font-pj">
                        Get started in 3 simple steps
                      </p>
                      <p className="mx-auto mt-5 text-base text-gray-500 max-w-md leading-6 font-pj">
                        Follow these steps to start earning
                      </p>
                    </div>
                    <div className="overflow-hidden text-center mx-auto max-w-2xl rounded-3xl p-8 mt-10">
                      <div className="text-center sm:grid-cols-3 grid grid-cols-1 gap-y-10 gap-x-12">
                        <div>
                          <div
                            className="flex items-center justify-center mx-auto bg-gray-800 border-gray-200 w-28 h-28 shadow-md
                    rounded-3xl"
                          >
                            <span className="text-[42px] text-bold inline-flex ml-1">
                              👀​
                            </span>
                          </div>
                          <p className="text-sm font-medium text-gray-800 font-pj mt-4">
                            Create Account
                          </p>
                        </div>
                        <div>
                          <div
                            className="flex items-center justify-center mx-auto bg-gray-800 border-gray-200 w-28 h-28 shadow-md
                    rounded-3xl"
                          >
                            <span className="text-[42px] text-bold inline-flex ml-1">
                              ​🧩
                            </span>
                          </div>
                          <p className="text-sm font-medium text-gray-800 mt-4">
                            Deposit Crypto
                          </p>
                        </div>
                        <div>
                          <div
                            className="flex items-center justify-center mx-auto bg-gray-800 border-gray-200 w-28 h-28 shadow-md
                    rounded-3xl"
                          >
                            <span className="text-[42px] text-bold text-gray-900 inline-flex ml-1">
                              ​🎉​
                            </span>
                          </div>
                          <p className="text-sm font-medium text-gray-800 font-pj mt-4">
                            Start earning
                          </p>
                        </div>
                      </div>
                      <div className="inline-flex relative group mt-24">
                        <div
                          className="absolute duration-1000 rotate-180 transition-all opacity-70 -inset-px rounded-xl blur-lg
                  filter group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200"
                        ></div>
                        <NavLink
                          to="/sign-up"
                          className="inline-flex justify-center items-center w-full px-8 py-4 text-base font-semibold
                  text-white bg-gray-900 sm:w-auto relative transition-all duration-200 border border-tansparent
                  rounded-md hover:bg-gray-700 focus:bg-gray-700"
                        >
                          Sign-up
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bg-white pt-16 pr-4 pb-16 pl-4 mt-0 mr-auto mb-0 ml-auto lg:max-w-screen-xl sm:max-w-xl md:max-w-full
        md:px-24 lg:px-8 lg:py-20"
              >
                <div className="grid grid-cols-2 row-gap-8 md:grid-cols-4">
                  <div className="text-center md:border-r">
                    <p className="text-center text-4xl mb-2 font-bold lg:text-5xl xl:text-6xl text-blue-700">
                      1M+
                    </p>
                    <p className="text-center text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                      Users
                    </p>
                  </div>
                  <div className="text-center md:border-r">
                    <p className="text-center text-4xl mb-2 font-bold text-blue-700 lg:text-5xl xl:text-6xl">
                      $53B
                    </p>
                    <p
                      className="text-center text-sm font-medium tracking-widest text-gray-800 uppercase
              lg:text-base"
                    >
                      Transacted
                    </p>
                  </div>
                  <div className="text-center md:border-r">
                    <p className="text-center text-4xl mb-2 font-bold text-blue-700 lg:text-5xl xl:text-6xl">
                      $2B
                    </p>
                    <p className="text-center text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                      Assets on Platform
                    </p>
                  </div>
                  <div className="text-center md:border-r">
                    <p className="text-center text-4xl mb-2 font-bold text-blue-700 lg:text-5xl xl:text-6xl">
                      20+
                    </p>
                    <p className="text-center text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                      Countries
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-4 md:px-10 lg:px-28 py-12">
                <FAQ />
              </div>
              <div class="py-20 bg-blue-500 text-white text-center">
                <p className="text-4xl mb-4 font-bold">
                  Become an ethereum validator{" "}
                </p>

                <p className="text-2xl mb-12">
                  Start staking with as little as 32ETH Earn profit on your
                  crypto
                </p>

                <a
                  class="py-4 px-8 bg-white text-blue-500 rounded"
                  href="/signup"
                  onClick="sendBiEvent('Banner_click', {});"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
