import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Cookies from "js-cookie";
import BalanceCard from "../BalanceCard/BalanceCard";
import { Typography, Grid } from "@mui/material";
import { useAuth } from "../../context/auth";
import CurrencySelect from "../CurrencySelect";
import { deposit, getAddresses } from "../../api/balance";
import LoadingScreen from "../LoadingScreen";
import { coinsAbv } from "../../utils/coins";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNotification } from "../../context/notif";
import { Formik, Form, Field } from "formik";
import { useDashboardSection } from "../../context/dashSection";
import FormButton from "../FormButton";
import { NavLink } from "react-router-dom";

export default function Receive() {
  const { currentSection, setCurrentSection } = useDashboardSection();
  const [seletedCurrency, setseletedCurrency] = useState("bitcoin");
  const [walletsLoading, setWalletsLoading] = useState(true);
  const [walletAdresses, setWalletAddresses] = useState([]);
  const [displayWallet, setDisplayWallet] = useState(false);
  const { user, loading } = useAuth();
  const [buttonState, setButtonState] = useState(false);
  const [authError, setAuthError] = useState(null);
  const { addNotification } = useNotification();

  useEffect(() => {
    setDisplayWallet(false);
  }, [seletedCurrency]);

  const receiveRequest = async (values) => {
    let amount = values.amount;
    try {
      const token = Cookies.get("token");
      const response = await deposit(
        token,
        JSON.stringify({
          amount,
          currency: coinsAbv[seletedCurrency],
        })
      );
      if (response.status === 200) {
        // addNotification({
        //   title: "Success",
        //   message: "Withdrawal Request successful",
        //   level: "success",
        // });
        setDisplayWallet(true);
      }
      setButtonState(false);
    } catch (err) {
      setButtonState(false);
      addNotification({
        title: "Error",
        message: "An Unexpected Error has occured",
        level: "error",
      });
      if (!err?.response) {
        setAuthError("No Server Response");
      } else {
        setAuthError("An Unexpected Error has occured");
      }
    }
  };

  useEffect(() => {
    async function loadWallets() {
      const token = Cookies.get("token");
      if (token) {
        const wallets = await getAddresses(token);
        setWalletAddresses(wallets.data);
      }
      setWalletsLoading(false);
    }
    loadWallets();
  }, []);
  if (loading || walletsLoading)
    return <LoadingScreen loading={loading || walletsLoading} />;

  return (
    <>
      <div className="flex flex-col max-w-sm  mb-8 border bg-black border-gray-500 rounded text-white">
        <h3 className="p-4 border-b border-gray-500 text-lg font-semibold">
          My Stakings
        </h3>
        <NavLink to="/staking" className="p-4 flex gap-3 hover:bg-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            height="48"
            width="48"
            data-element="Learn"
          >
            <path
              fill="#BFE9FF"
              d="M30 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
            ></path>
            <path
              fill="#56B4FC"
              d="M3 24c0-8.475 7.05-15.3 15.525-15C26.7 9.3 33 16.2 33 24.375V48H15v-9h-4.5c-1.65 0-3-1.35-3-3v-6H3v-6z"
            ></path>
            <path
              fill="#1652F0"
              d="M18.525 9c-.825 0-1.575 0-2.325.15-.75 1.8-1.2 3.825-1.2 5.85 0 8.25 6.75 15 15 15 1.05 0 2.025-.075 3-.3v-5.325C33 16.2 26.7 9.3 18.525 9z"
            ></path>
            <path
              fill="#fff"
              d="m24 13.5 1.05 3.45L28.5 18l-3.45 1.05L24 22.5l-1.05-3.45L19.5 18l3.45-1.05L24 13.5z"
            ></path>
          </svg>
          <div>
            <h4>Learn about Staking</h4>
            <p className="text-gray-500">See how we calculate rewards</p>
          </div>
        </NavLink>
      </div>
      <Grid container spacing={3}>
        <BalanceCard
          action="deposit"
          balance={user.balances.total.toFixed(2)}
          onClick={() => setCurrentSection("receive")}
        />
        <BalanceCard
          action="stake"
          title="Total Stakes"
          balance={user.balances.total_stakes.toFixed(2)}
          onClick={() => setCurrentSection("staking")}
        />
      </Grid>
      {displayWallet && (
        <Container className="flex flex-col items-center">
          <Typography variant="h6" className="text-white my-6 mt-10">
            {seletedCurrency.toUpperCase()} WALLET
          </Typography>
          <p className="text-white mb-6 text-center">
            Scan the barcode below or copy the wallet address to deposit{" "}
          </p>
          <img
            src={`data:image/png;base64,${
              walletAdresses[`${coinsAbv[seletedCurrency]}_qrcode`][
                "image_base64"
              ]
            }`}
            className="w-44 lg:w-56"
          />
          <div className="p-4 border rounded flex mt-4">
            <Typography variant="h6" className="text-white text-sm md:text-xl mr-2 max-w-[200px] md:max-w-md break-words">
              {walletAdresses[`${coinsAbv[seletedCurrency]}`]}
            </Typography>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                navigator.clipboard.writeText(
                  walletAdresses[`${coinsAbv[seletedCurrency]}`]
                );
                addNotification({
                  title: "Success",
                  message: "Address Copied!!",
                  level: "success",
                });
              }}
              edge="end"
              className="text-white"
            >
              <ContentCopyIcon />
            </IconButton>
          </div>
        </Container>
      )}

      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
        className="flex justify-center items-center px-0 mb-36"
      >
        <Formik
          initialValues={{
            amount: "",
          }}
          onSubmit={(values) => {
            setButtonState(true);
            receiveRequest(values);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            validateOnChange,
            /* and other goodies */
          }) => (
            <Form className="flex flex-col p-8 border border-slate-600 text-white rounded-sm w-full">
              <Typography variant="h5" className="text-white mb-6">
                Choose Currency
              </Typography>
              <CurrencySelect
                seletedCurrency={seletedCurrency}
                setseletedCurrency={setseletedCurrency}
              />
              <div className="w-full">
                <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                  Amount
                </p>
                <Field
                  type="number"
                  name="amount"
                  className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                  placeholder="$10000"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                />
                <div className="text-red-400 text-xs">
                  {errors.amount && touched.amount && errors.amount}
                </div>
              </div>

              <div className="form-submit">
                <FormButton buttonState={buttonState} value="Receive" />
              </div>
              <div className="text-red-400 mt-4">
                {authError ? <p>{authError} </p> : null}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
