import React from "react";
import Faq from "react-faq-component";

const data = {
  title: "Frequently Asked questions (FAQ)",
  rows: [
    {
      title: "What is a web wallet?",
      content: `A web wallet is a digital tool that allows you to securely store, send, and receive digital assets / cryptocurrencies. It works similarly to a traditional wallet, but it's digital and uses cryptographic algorithms to ensure the safety of your funds.`,
    },
    {
      title: "Can I store Fiat currency?",
      content: `Yes, you can convert your fiat currency and securely store them as digital assets with us, providing you an extra layer of security and higher interest rates.`,
    },
    {
      title: "What is staking?",
      content:
        "Staking is a process by which you can earn rewards for holding certain cryptocurrencies in your wallet. It involves locking up your funds for a period of time to help secure the network and validate transactions, and in return, you receive a percentage of the network's fees or new coin emissions.",
    },
    {
      title: "How can I earn Airdrops?",
      content:
        "Unlike traditional airdrop farming, with Paxcrypt you do not need to farm your airdrops manually. We have a team of experts, connections with huge projects and a suite of tools that farm airdrops for our users as long as you hold your crypto with us.",
    },
    {
      title: "How does staking work in a crypto wallet app?",
      content: `If your crypto wallet app supports staking, you can typically choose which cryptocurrency you want to stake and how much you want to stake. Once you've locked up your funds, the app will automatically participate in the network's staking process on your behalf, and you'll receive rewards proportional to your contribution.`,
    },
    {
      title:
        "Are there any risks associated with staking in a crypto wallet app?",
      content: `As with any investment, staking comes with risks. One potential risk is that the value of the cryptocurrency you're staking could decrease, leading to a loss of value in your staked funds. But with Paxcrypt, you can prevent this loss when your coins are staked`,
    },
    {
      title: "How can I maximize my staking rewards in a crypto wallet app?",
      content: `To maximize your staking rewards, you can typically choose to stake for longer periods of time, stake larger amounts of cryptocurrency, or participate in staking pools that combine the staking power of multiple users. Additionally, you may want to do research on which cryptocurrencies have the highest staking rewards and the lowest associated risks.`,
    },
    {
      title: "How does Paxcrypt ensure the safety of my funds?",
      content: `The crypto wallet app may use various security measures, such as encryption, multi-factor authentication, and cold storage, to protect your funds from unauthorized access and theft. Additionally, the app may be regularly audited and tested for vulnerabilities to ensure the highest level of security.`,
    },
  ],
};

const config = {
  animate: true,
};

export default function FAQ() {
  return (
    <div>
      <Faq data={data} config={config} />
    </div>
  );
}
