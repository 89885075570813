import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import LicensesAndRegisterations from "./pages/LicensesAndRegisterations";
import About from "./pages/About";
import CookiesPolicy from "./pages/CookiesPolicy";
import Home from "./pages/Home";
import Privacy from "./pages/PrivacyPolicy";
import Referral from "./pages/Referral";
import Terms from "./pages/TermsAndConditions";
import LoginPage from "./pages/Login";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
// import { useAuth } from "./context/auth";
import { Crisp } from "crisp-sdk-web";
import Careers from "./pages/Careers";
import Staking from "./pages/Staking";
import VerifyEmail from "./pages/VerifyEmail";
import OTC from "./pages/OTC";
import FAQPage from "./pages/FAQ";
import EthereumStaking from "./pages/EthereumStaking";

function App() {
  // const { user } = useAuth();
  useEffect(() => {
    Crisp.configure("c5b2feb8-2cd1-4c65-a754-bcddfd441e90");
  }, []);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="cookies-policy" element={<CookiesPolicy />} />
      <Route
        path="licenses-and-registrations"
        element={<LicensesAndRegisterations />}
      />
      <Route path="privacy-policy" element={<Privacy />} />
      <Route path="referral" element={<Referral />} />
      <Route path="terms-and-conditions" element={<Terms />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="careers" element={<Careers />} />
      <Route path="staking" element={<Staking />} />
      <Route path="ethereum-staking" element={<EthereumStaking/>} />
      <Route path="otc" element={<OTC/>} />
      <Route path="faq" element={<FAQPage/>} />
      <Route path="verify-email" element={<VerifyEmail/>} />
      {/* <Route path="dashboard" element={<SignUp/>} /> */}
      <Route
        path="dashboard"
        element={
          // <ProtectRoute>
          <Dashboard />
          // </ProtectRoute>
        }
      />

      <Route path="*" element={<p>There's nothing here: 404!</p>} />
      {/* <Route path="/users">
        <Users />
      </Route> */}
      {/* <Route path="/">
        <Home />
      </Route> */}
    </Routes>
  );
}

export default App;
